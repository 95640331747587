import React from 'react'
import { Link } from 'react-router-dom'
import vedio from '../../Assets/vedio/customvedio.mp4';
// import vedio from '../../Assets/vedio/Studio_Project.mp4';
export default function Hero() {    
    return (
        <section className='global-hero'>
            <div className="video-wrap">
                <video id="video" className='d-none d-xl-block' autoPlay controls="" loop muted type="video/mp4" preload="none" >
                    <source src={vedio}></source>
                </video>
                <video id="video" className='d-block d-xl-none' autoPlay controls="" loop muted type="video/mp4" preload="none" >
                    <source src={vedio}></source>
                </video>
                    <div className="video-overlay"></div>
            </div>
            <div className='w-100 hero container'>
                <p className='fs-2 d-none d-lg-block' data-aos="fade-right">نحو موارد بشرية سعودية مبتكرة</p>
                <p className='fs-6 d-block d-lg-none' data-aos="fade-right">نحو موارد بشرية سعودية مبتكرة</p>
                <div className='buttons'>
                    <Link to={"MembershipsSingls"} className='btn btn-secondary ms-2 d-none d-lg-block'>انضم الان</Link>
                    <Link to={"Contact"} className='btn btn-secondary me-2 d-none d-lg-block'>تواصل معنا</Link>
                </div>
            </div>
        </section>
    )
}