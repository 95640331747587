import React, { useState } from 'react'
import { dbcvs, storagecvs } from '../../firebase/firebaseCV';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
export default function Addcv() {
    const [data,setData] = useState({
        degree:"SECONDARY SCHOOL | الثانوية العامة",
        experince:"خريج جديد",
        gender:"ذكر",
        national:"HUMAN RESOURCES (HR) | الموارد البشرية"
    });
    const [prog,setProg] = useState(0);
    const navigator = useNavigate();
    const [done,setDone] = useState(false);
    const [uploaded,setUploade] = useState(false);
    const handleImage = async(file)=>{
        const displayNAme = new Date().getTime() + file.name;
        const storageRef  = ref(storagecvs, displayNAme);
        const uploadTask  = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProg(progress);
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, 
            (error) => {
                console.log(error);
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setData({...data,cvfile:`${downloadURL}`});
                    setUploade(true);
                });
            }
        );
    }
    const handleSubmit = async()=>{
        try{
            if(data.cvfile!==undefined){
                await addDoc(collection(dbcvs,"cv"),{...data,timeStamp:serverTimestamp()});
                setData({});
                setDone(true);
                let handler = setTimeout(()=>{
                    navigator(0);
                    clearTimeout(handler);
                },1000);
            }
        }catch(err){
            console.log(err);
        }
    };
    return (
        <div className='Addcv mt-3'>
            <p className=''></p>
            <form onSubmit={(e)=>{
                e.preventDefault();
                handleSubmit(e);
            }}>
                <p className='text-danger text-end'>*يرجى رفع السيره الذاتية اولا*</p>
                <div className="mb-3">
                    <label htmlFor="formFile" className="form-label text-right w-100">اضف السيره الذاتية<span>*</span></label>
                    <input className="form-control" type="file"  id="formFile" accept=".pdf,.docx" onChange={(e)=>{
                        handleImage(e.target.files[0]);
                    }}/>
                    <p className='p-0 m-0 mt-1 text-right text-danger'>يرجى تحميل فقط ملفات pdf او word</p>
                </div>
                <ProgressBar percent={prog} filledBackground="#1884c2"/>
                <div className='d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap my-3'>
                    <div className='w-100 w-lg-50 ms-lg-3 d-flex justify-content-center align-items-start flex-column' value={data.name!==undefined?data.name:""}>
                        <label>الاسم<span>*</span></label>
                        <input disabled={!uploaded&&true} placeholder='الاسم' className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                            setData({...data,name:e.target.value});
                        }}/>
                    </div>
                    <div className='w-100 w-lg-50 d-flex justify-content-center align-items-start flex-column px-1' value={data.email!==undefined?data.email:""}>
                        <label>البريد الالكترونى<span>*</span></label>
                        <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100" placeholder='البريد الالكترونى'  required type={"email"}  onChange={(e)=>{
                            setData({...data,email:e.target.value});
                        }}/>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center  flex-wrap flex-lg-nowrap mb-3'>
                    <div className='w-100 w-lg-50  ms-lg-3 d-flex justify-content-center align-items-start flex-column'>
                        <label>سنوات الخبره<span>*</span></label>
                        <select disabled={!uploaded&&true} disabled={!uploaded&&true} className="form-select  mt-2 w-100 px-1" aria-label="Default select example"  onChange={(e)=>{
                            setData({...data,experince:e.target.value});
                        }}>
                            <option value="خريج جديد">خريج جديد</option>
                            <option value="سنه">سنه</option>
                            <option value="سنتين">سنتين</option>
                            <option value="3 سنوات">3 سنوات</option>
                            <option value="4 سنوات">4 سنوات</option>
                            <option value="5 سنوات">5 سنوات</option>
                            <option value="6 سنوات">6 سنوات</option>
                            <option value="7 سنوات">7 سنوات</option>
                            <option value="8 سنوات">8 سنوات</option>
                            <option value="9 سنوات">9 سنوات</option>
                            <option value="10 سنوات">10 سنوات</option>
                            <option value="اكثر من 10 سنوات">اكثر من 10 سنوات</option>
                        </select>
                    </div>
                    <div className='w-100 w-lg-50  d-flex justify-content-center align-items-start flex-column px-1'>
                        <label>الجنس<span>*</span></label>
                        <select disabled={!uploaded&&true} className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                            setData({...data,gender:e.target.value});
                        }}>
                            <option value="ذكر">ذكر</option>
                            <option value="انثى">انثى</option>
                        </select>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center  flex-wrap flex-lg-nowrap mb-3'>
                    <div className='w-100 w-lg-50  ms-lg-3 d-flex justify-content-center align-items-start flex-column'>
                        <label>المؤهل<span>*</span></label>
                        <select disabled={!uploaded&&true} className="form-select mt-2 w-100 px-1" aria-label="Default select example"  onChange={(e)=>{
                            setData({...data,degree:e.target.value});
                        }}>
                            <option value="0">اختار</option>
                            <option value="SECONDARY SCHOOL | الثانوية العامة">SECONDARY SCHOOL | الثانوية العامة</option>
                            <option value="DIPLOMA | دبلوم">DIPLOMA | دبلوم</option>
                            <option value="Bachelor | بكالوريوس">Bachelor | بكالوريوس</option>
                            <option value="Master | ماجستير">Master | ماجستير</option>
                            <option value="PhD | دكتوراة">PhD | دكتوراة</option>
                            <option value="OTHER | أخرى">OTHER | أخرى</option>
                        </select>
                    </div>
                    <div className='w-100 w-lg-50  d-flex justify-content-center align-items-start flex-column px-1'>
                        <label>مجال العمل<span>*</span></label>
                        <select disabled={!uploaded&&true} className="form-select mt-2 w-100 px-1"  aria-label="Default select example" onChange={(e)=>{
                            setData({...data,national:e.target.value});
                        }}>
                        <option value="0">اختار</option>
                            <option value="HUMAN RESOURCES (HR) | الموارد البشرية">HUMAN RESOURCES (HR) | الموارد البشرية</option>
                            <option value="ACCOUNTING AND FINANCE | المحاسبة والمالية">ACCOUNTING AND FINANCE | المحاسبة والمالية</option>
                            <option value="MARKETING AND ADVERTISING | التسويق والإعلان">MARKETING AND ADVERTISING | التسويق والإعلان</option>
                            <option value="INFORMATION TECHNOLOGY | تقنية المعلومات">INFORMATION TECHNOLOGY | تقنية المعلومات</option>
                            <option value="OPERATIONS | العمليات">OPERATIONS | العمليات</option>
                            <option value="CUSTOMER SERVICE | خدمة العملاء">CUSTOMER SERVICE | خدمة العملاء</option>
                            <option value="PURCHASING | المشتريات">PURCHASING | المشتريات</option>
                            <option value="LEGAL | القانونية">LEGAL | القانونية</option>
                            <option value="MEDICINE &amp; HEALTH CARE | الطب والرعاية الصحية">MEDICINE &amp; HEALTH CARE | الطب والرعاية الصحية</option>
                            <option value="INTERNAL AUDIT | المراجعة الداخلية">INTERNAL AUDIT | المراجعة الداخلية</option>
                            <option value="ADMINISTRATIONS | الشؤون الادارية">ADMINISTRATIONS | الشؤون الادارية</option>
                            <option value="COMPLIANCE | الإلتزام">COMPLIANCE | الإلتزام</option>
                            <option value="BANKING | الخدمات المصرفية">BANKING | الخدمات المصرفية</option>
                            <option value="SECRETARY | سكرتارية">SECRETARY | سكرتارية</option>
                            <option value="OTHERS | أخرى">OTHERS | أخرى</option>
                        </select>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center  flex-wrap flex-lg-nowrap mb-3'>
                    <div className='w-100 p-0  d-flex justify-content-center align-items-start flex-column' value={data.text!==undefined?data.text:""}>
                        <label>التوصيات<span>*</span></label>
                        <textarea disabled={!uploaded&&true} name="c_note" className="form-control w-100" id="" onChange={(e)=>{
                            setData({...data,text:e.target.value});
                        }}></textarea>
                    </div>
                </div>
                <button type="submit" disabled={!uploaded&&true} className="btn btn-primary">الانضمام</button>
            </form>
            <div className={`massage-customs ${!done&&"active"}`}>
                <i className="bi bi-x-square-fill" onClick={()=>{setDone(false)}}></i>
                تمم رفع السيره الذاتينه بنجاح
            </div>
        </div>
    )
}
