import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react'
import { useDownloadExcel } from 'react-export-table-to-excel';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { dbmembers, storagemembers } from '../../firebase/firebasemembers';
export default function UsersMemberships() {
    const navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../../signup');
    }
    const [users,setUsers] = useState(undefined);
    const tableRef = useRef(null);
        const [reload,setReload] = useState(true);
    useEffect(()=>{
        getData();
    },[]);
    const deletehandler = async(eid,imagLink)=>{
        try{
            await deleteDoc(doc(dbmembers, "memberships", eid));
            const desertRef = ref(storagemembers, `${imagLink}`);
            deleteObject(desertRef).then(() => {
                getData();
            }).catch((error) => {
            });
        }catch(e){
            console.log(e);
        }
    }
    const getData = async()=>{
        try{
            const q = query(collection(dbmembers, "memberships"));
            onSnapshot(q, (querySnapshot) => {
                    let mutli = [];
                    let counter = 0;
                    querySnapshot.forEach((doc) => {
                        mutli.push(<tr key={doc.id}>
                            <td><i className="bi bi-x-square-fill text-nowrap deleting" onClick={(e)=>{
                                deletehandler(doc.id,(doc.data()).img);
                            }}></i></td>
                            <td>{counter}</td>
                            <td>{(doc.data()).name}</td>
                            <td>{(doc.data()).type}</td>
                            <td>{(doc.data()).price}</td>
                            <td>{(doc.data()).national}</td>
                            <td>{(doc.data()).Employer}</td>
                            <td>{(doc.data()).JobTitle}</td>
                            <td>{(doc.data()).city}</td>
                            <td>{(doc.data()).gender}</td>
                            <td>{(doc.data()).currentBusiness}</td>
                            <td>{(doc.data()).intersts}</td>
                            <td><a className='text-primary bg-transparent border-0' target={"_blank"} href={(doc.data()).linkdin}><i className="bi bi-linkedin"></i></a></td>
                            <td>{(doc.data()).phone}</td>
                            <td><a className='text-primary bg-transparent border-0' target={"_blank"} href={(doc.data()).img}><i className="bi bi-eye fs-5"></i></a></td>
                        </tr>);
                        counter++;
                    });
                    setUsers([...mutli]);
            });
        }catch(e){
            console.log(e);
        }
    }
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'UsersmemberShip_table',
        sheet: 'UsersmemberShip'
    })
    return (
        <section className='container'>
            <button className='btn btn-primary ms-3' disabled={(users!==undefined&&users.length===0)?true:false} onClick={()=>{
                setReload(!reload);
            }}>اريد تحميل الجدول</button>
            <button className='btn btn-primary ms-auto' disabled={(users!==undefined&&users.length===0)?true:false} onClick={onDownload}>فتح فى اكسيل شيت</button>
            <div className="table-responsive mt-3">
            {(users!==undefined)?
                    (users.length!==0)?<table  ref={tableRef} className="table table-secondary">
                        <thead>
                            <tr>
                            <th>امسح</th>
                            <th className='text-nowrap'>#رقم</th>
                            <th className='text-nowrap'><i className="bi bi-person-fill"></i> الاسم</th>
                            <th className='text-nowrap'><i className="bi bi-person-fill"></i> نوع العضويه</th>
                            <th className='text-nowrap'><i className="bi bi-person-fill"></i>  المبلغ</th>
                            <th className='text-nowrap'><i className="bi bi-flag-fill"></i> الجنسيه</th>
                            <th className='text-nowrap'><i className="bi bi-person-fill"></i> جهه العمال</th>
                            <th className='text-nowrap'><i className="bi bi-globe-asia-australia"></i> المسمى الوظيفى</th>
                            <th className='text-nowrap'><i className="bi bi-person-fill"></i> مدينه الاقامه</th>
                            <th className='text-nowrap'>النوع</th>
                            <th className='text-nowrap'>قطاع العمل</th>
                            <th className='text-nowrap'>الاهتمامات</th>
                            <th className='text-nowrap'>لينكدان</th>
                            <th className='text-nowrap'>رقم الهاتف</th>
                            <th className='text-nowrap'>صوره الايصال</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users}
                        </tbody>
                    </table>
                        :<div>لا يوجد طلبات عضويات الان</div>:<div className='w-100 mt-5 d-flex justify-content-center align-items-center'>
                            <ReactLoading type={"spinningBubbles"} color={"#1884c2"} height={150} width={150} />
                        </div>}
            </div>
        </section>
    )
}
