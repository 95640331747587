import React from 'react'
import Events from './Home/Events'
import Hero from './Home/Hero'
import News from './Home/News'
import Parteners from './Home/Parteners'
import Tweets from './Home/Tweets'

export default function Home() {
    return (
        <>
            <Hero />
            <Events />
            <News />
            <Parteners />
            <Tweets />
        </>
    )
}
