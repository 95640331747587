import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { TwitterEmbed } from 'react-social-media-embed';
import { collection, getDocs } from 'firebase/firestore';
import { dbtweets } from '../../firebase/firebasetweets';
export default function Tweets() {
    const [tweets,setTweets] = useState([]);
    useEffect(()=>{
        getData();
    },[]);
    const getData = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbtweets, "tweets"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setTweets([...(mutli.reverse())]);
        }catch(e){
            console.log(e);
        }
    }
    const settings = {
        dots: true,
        infinite: tweets.length>3?true:false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: tweets.length>2?true:false,
                    dots: true
                }
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite:true,
                }
            }
        ]
    };
    return (
        <section className='mt-5 events container'>
            <h2 className="main-title mb-4 ">اخر التغريدات</h2>
            <Slider {...settings}>
                {(tweets.length!==0)?tweets.map((item,index)=>{
                    return(<div key={index} className="w-100 custom-card px-0 px-lg-2">
                        <TwitterEmbed url={item.url} style={{height:"510px" , overflow:"auto"}}/>
                    </div>)
                }):<p>لا يوجد فعاليات الان</p>}
            </Slider>
        </section>
    )
}
