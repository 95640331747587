import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const newsProjectFirebaseConfig = {
    apiKey: "AIzaSyA5XJS5nOTOSLpdITgZ8kE80V0r0DNA1qE",
    authDomain: "ihrnews-57a0a.firebaseapp.com",
    projectId: "ihrnews-57a0a",
    storageBucket: "ihrnews-57a0a.appspot.com",
    messagingSenderId: "1061727433354",
    appId: "1:1061727433354:web:f1a9e5e5703ad6d6e2f373",
    measurementId: "G-HCTLR9YK7W"
};

// Initialize Firebase
const appnews = initializeApp(newsProjectFirebaseConfig, "News");

export const authnews = getAuth(appnews);

export const dbnews = getFirestore(appnews);

export const storagenews = getStorage(appnews);