import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { collection, getDocs } from "firebase/firestore";
import { dbevents } from "../../firebase/firebaseevents";
import { Link } from "react-router-dom";
export default function Events() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };
    const [eventss,setEventss] = useState([]);
    useEffect(()=>{
        getData();
    },[]);
    const getData = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbevents, "events"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setEventss([...(mutli)]);
        }catch(e){
            console.log(e);
        }
    }
    return (
        <section className='mt-4 mt-lg-5 events container'>
            <h2 className="main-title mb-lg-4 mb-1 ">الفعاليات</h2>
            <Slider {...settings}>
                {(eventss.length!==0)?eventss.map((item)=>{
                    const style = {
                        objectFit:"contain",
                        objectPosition: "center",
                        width: "100%",
                        height: "600px",
                    }
                    return(<div key={item.id} className="custom-card">
                        <img className="responsive-image" style={{...style}} src={item.img} alt='iageCa' />
                        <Link to={`/events/${item.title}`} className="btn btn-primary my-2">طلب تسجيل</Link>
                    </div>)
                }):<p>لا يوجد فعاليات الان</p>}
            </Slider>
        </section>
    )
}
