import { collection, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { dbcvs } from '../../firebase/firebaseCV';
import ReactLoading from 'react-loading';
export default function Showcv() {
    const [cvs,setCvs] = useState(undefined);
    const [page,setPage] = useState(0);
    const [last,setLast] = useState(0);
    const [pagnations,setPagnations] = useState([0]);
    const [maxpage,setmaxpage] = useState(1);
    const [data,setData] = useState({});
    const [clear,setClear] = useState(false);
    const onButtonClick = async(link) => {
        let alink = document.createElement('a');
        alink.href = link;
        alink.target = "__blank";
        alink.download = 'السيره الذاتية.pdf';
        alink.click();
    }
    useEffect(()=>{
        getMax();
        getData();
    },[page,clear]);
    const getData = async()=>{
        try{
            let mutli = [];
            const next = query(collection(dbcvs, "cv"),
                orderBy("timeStamp"),
                startAfter(last),
                limit(10));
            const querySnapshot = await getDocs(next);
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
            let counter = 1;
            querySnapshot.forEach((doc) => {
                mutli.push(<tr key={doc.id}>
                    <td>{counter}</td>
                    <td>{(doc.data()).name}</td>
                    <td>{(doc.data()).national}</td>
                    <td>{(doc.data()).degree}</td>
                    <td>{(doc.data()).experince}</td>
                    <td><p className='nobza-custom'>{(doc.data()).text}</p></td>
                    <td><button className='text-primary bg-transparent border-0' onClick={()=>{
                        onButtonClick((doc.data()).cvfile)
                    }}><i className="bi bi-eye fs-5"></i></button></td>
                </tr>);
                counter++;
            });
            setLast(lastVisible);
            // filteration([...mutli])
            setCvs([...mutli])
            // setPage(page+1);
        }catch(e){
            console.log(e);
        }
    }
    const getMax = async()=>{
        try{
            const querySnapshotmax = await getDocs(collection(dbcvs, "cv"));
            setmaxpage(querySnapshotmax._snapshot.docChanges.length);
        }catch(e){
            console.log(e);
        }
    }   
    const filteration = (mutli)=>{
        let afterfilteration= mutli.filter((el)=>{
            let filter = false;
            if(data.degree!==undefined){    
                if(((el.props.children[3].props.children)===data.degree)){
                    filter=true;
                }
                else{
                    return false;
                }
            }
            if(data.experince!==undefined){
                if(((el.props.children[4].props.children)===data.experince)){
                    filter=true;
                }else{
                    return false;
                }
            }
            if(data.national!==undefined){
                if((el.props.children[2].props.children)===data.national){
                    filter=true;
                }
                else{
                    return false;
                }
            }
            // console.log(filter);
            return filter;
        });
        setCvs([...afterfilteration]);
    }
    const submitsearc = async(e)=>{
        e.preventDefault();
        filteration([...cvs]);
    }
return (
    <>
    {(cvs!==undefined)?
        (cvs.length!==0)?<React.Fragment>
            <div className='search w-100 mt-3'>
                <form onSubmit={(e)=>{
                    submitsearc(e);
                }} className='d-flex flex-wrap justify-content-between align-items-end'>
                    <div className='w-100 w-lg-25  d-flex justify-content-center align-items-start flex-column'>
                        <label>المؤهل<span>*</span></label>
                        <select className="form-select mt-2 w-100 px-1" aria-label="Default select example"  onChange={(e)=>{
                            setData({...data,degree:e.target.value});
                        }}>
                            <option value="0">اختار</option>
                            <option value="SECONDARY SCHOOL | الثانوية العامة">SECONDARY SCHOOL | الثانوية العامة</option>
                            <option value="DIPLOMA | دبلوم">DIPLOMA | دبلوم</option>
                            <option value="Bachelor | بكالوريوس">Bachelor | بكالوريوس</option>
                            <option value="Master | ماجستير">Master | ماجستير</option>
                            <option value="PhD | دكتوراة">PhD | دكتوراة</option>
                            <option value="OTHER | أخرى">OTHER | أخرى</option>
                        </select>
                    </div>
                    <div className='w-100 w-lg-25  d-flex justify-content-center align-items-start flex-column'>
                        <label>سنوات الخبره<span>*</span></label>
                        <select className="form-select  mt-2 w-100 px-1" aria-label="Default select example"  onChange={(e)=>{
                            setData({...data,experince:e.target.value});
                        }}>
                            <option value="0">اختار</option>
                            <option value="خريج جديد">خريج جديد</option>
                            <option value="سنه">سنه</option>
                            <option value="سنتين">سنتين</option>
                            <option value="3 سنوات">3 سنوات</option>
                            <option value="4 سنوات">4 سنوات</option>
                            <option value="5 سنوات">5 سنوات</option>
                            <option value="6 سنوات">6 سنوات</option>
                            <option value="7 سنوات">7 سنوات</option>
                            <option value="8 سنوات">8 سنوات</option>
                            <option value="9 سنوات">9 سنوات</option>
                            <option value="10 سنوات">10 سنوات</option>
                            <option value="اكثر من 10 سنوات">اكثر من 10 سنوات</option>
                        </select>
                    </div>
                    <div className='w-100 w-lg-25  d-flex justify-content-center align-items-start flex-column '>
                        <label>مجال العمل<span>*</span></label>
                        <select className="form-select mt-2 w-100 px-1"  aria-label="Default select example" onChange={(e)=>{
                            setData({...data,national:e.target.value});
                        }}>
                        <option value="0">اختار</option>
                            <option value="HUMAN RESOURCES (HR) | الموارد البشرية">HUMAN RESOURCES (HR) | الموارد البشرية</option>
                            <option value="ACCOUNTING AND FINANCE | المحاسبة والمالية">ACCOUNTING AND FINANCE | المحاسبة والمالية</option>
                            <option value="MARKETING AND ADVERTISING | التسويق والإعلان">MARKETING AND ADVERTISING | التسويق والإعلان</option>
                            <option value="INFORMATION TECHNOLOGY | تقنية المعلومات">INFORMATION TECHNOLOGY | تقنية المعلومات</option>
                            <option value="OPERATIONS | العمليات">OPERATIONS | العمليات</option>
                            <option value="CUSTOMER SERVICE | خدمة العملاء">CUSTOMER SERVICE | خدمة العملاء</option>
                            <option value="PURCHASING | المشتريات">PURCHASING | المشتريات</option>
                            <option value="LEGAL | القانونية">LEGAL | القانونية</option>
                            <option value="MEDICINE &amp; HEALTH CARE | الطب والرعاية الصحية">MEDICINE &amp; HEALTH CARE | الطب والرعاية الصحية</option>
                            <option value="INTERNAL AUDIT | المراجعة الداخلية">INTERNAL AUDIT | المراجعة الداخلية</option>
                            <option value="ADMINISTRATIONS | الشؤون الادارية">ADMINISTRATIONS | الشؤون الادارية</option>
                            <option value="COMPLIANCE | الإلتزام">COMPLIANCE | الإلتزام</option>
                            <option value="BANKING | الخدمات المصرفية">BANKING | الخدمات المصرفية</option>
                            <option value="SECRETARY | سكرتارية">SECRETARY | سكرتارية</option>
                            <option value="OTHERS | أخرى">OTHERS | أخرى</option>
                        </select>
                    </div>
                    <button type="submit" className="btn h-25 btn-primary mt-3 ">ابحث <i className="bi bi-search"></i></button>
                    <button onClick={()=>{
                        setData({});
                        setLast(0);
                        setPagnations([0]);
                        setmaxpage(1);
                        setPage(0);
                        setClear(!clear);
                    }} className="btn h-25 btn-danger  mt-3 ">إعادة التهيئة <i className="bi bi-trash-fill"></i></button>
                </form>
            </div>
            <div className='w-100 my-3 d-flex justify-content-center align-items-center'>
                <button className='ms-2 w-custom btn btn-primary' disabled={(page===Math.floor(maxpage/10))?true:false} onClick={()=>{
                        setPage(page+1);
                        setPagnations([...pagnations,last]);
                }}><i className="bi bi-arrow-right-short fs-6"></i>التالى</button>
                <button className='btn w-custom btn-primary' disabled={(page===0)?true:false} onClick={async()=>{
                    setPage(page-1);
                    setLast(pagnations[page-1]);
                }}>السابق<i className="bi bi-arrow-left-short fs-6"></i></button>
            </div>
            <div className="table-responsive mt-3">
                        <table className="table table-secondary">
                            <thead>
                                <tr>
                                <th className='text-nowrap'>#رقم</th>
                                <th className='text-nowrap'><i className="bi bi-person-fill"></i> الاسم</th>
                                <th className='text-nowrap'><i className="bi bi-flag-fill"></i> مجال العمل</th>
                                <th className='text-nowrap'><i className="bi bi-person-fill"></i> المؤهل</th>
                                <th className='text-nowrap'><i className="bi bi-globe-asia-australia"></i> سنوات الخبره</th>
                                <th className='text-nowrap'><i className="bi bi-person-fill"></i> توصيات </th>
                                <th className='text-nowrap'>السيره الذاتية</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cvs}
                            </tbody>
                        </table>
            </div>
        </React.Fragment>:<div>لا يوجد سير ذاتيه الان</div>
        :<div className='w-100 mt-5 d-flex justify-content-center align-items-center'><ReactLoading type={"spinningBubbles"} color={"#1884c2"} height={150} width={150} /></div>}
    </>
)
}