export const opinons = [
    {
        id:1,
        name:"أ/إبراهيم العامر ",
        pos:"نائب الرئيس التنفيذي للموارد البشرية , مجموعة الدكتور سليمان الحبيب الطبية",
        opn:`تشرَّفت بالانضمام لهذا التجمع الاحترافي والذي يهدف لنشر وتطوير الوعي الفكري والمهاري لمهنة "إدارة الموارد البشرية الحديثة" وذلك من خلال تنظيم فعاليات ومحاضرات يلقيها نخبة من المحترفين والخبراء في هذا المجال. حيث كسب هذا التجمع سمعه راقية في أوساط المحترفين ولا سيما ارتباطه بجامعة اليمامة المتميزة باعتبارها من أوائل من اعتنو بمجال إدارة الموارد البشرية أكاديمياً. بلا شك أن هذا التجمع وما يملكه من إدارة قوية واحترافية مدعومة بمقومات وشراكات ضخم، سيصبح نواة أساسية وفاعلة لتطوير مهنة الموارد البشرية. 
        `
    },
    {
        id:2,
        name:"أ/خالد المالكي",
        pos:"نائب رئيس أول ، قطاع الموارد البشرية , بنك الرياض",
        opn:`كان ولا يزال من دواعي سروري تواجدي من ضمن مجتمع الموارد البشرية والذي يضم نخبة من المتخصصين بالموارد البشرية والذين يعتبرون مصدر  فخر لنا داخل وطننا الغالي حفظه الله  ويعكس مدى التطور والمشاركة المعرفية لدينا واتمنى من الله التوفيق والاستمرار بالعطاء من خلال المجتمع حيث يشكل نقله نوعيه للمجتمعات المهنية. 
        `
    },
    {
        id:3,
        name:"أ/حياة العياشي",
        pos:"المدير التنفيذي للمكافآت والتطوير للشرق الأوسط وأفريقيا وآسيا الوسطى , شركة ألستوم",
        opn:`جمع واعد متطلع يسعى للتميز من خلال استقطاب مجموعة متميزه من الشباب السعودي الناشط في مجال الموارد البشرية. لقد أظهر التجمع قوة في الحضور والأثر وترك بصمة واضحة خلال فترة وجيزة من انطلاقته وذلك من خلال الفعاليات التي أقامها والتي استهدفت أهم الجوانب المتعلقة بمجال الموارد البشرية.
        باعتقادي إذا استمر المجتمع على ذات النهج والجودة في اِنتقاء المواضيع ذات الصلة، فإنه سيحقق أهدافه خلال فترة استثنائية وسيساهم في رفع مستوى الوعي لدى المهنيين في مجال الموارد البشرية. كل التوفيق لأصحاب المجتمع.
        `
    },
    {
        id:4,
        name:"أ/ منير الفهيدي ",
        pos:"نائب الرئيس التنفيذي للموارد البشرية ، شركة علم",
        opn:`مجتمع الموارد البشرية، رؤية واعدة لشباب واعد، ينظر للموارد البشرية بافق واسعة، وطموحات كبيرة لنقل ممارسات الموارد البشرية وتطويرها بما يتوافق والتطورات الاقتصادية في المنطقة، سعيد بهذا المجتمع، وفخور بهؤلاء المبدعين..
        `
    },
    {
        id:5,
        name:"أ/فواز العمران ",
        pos:"KPMG مدير عام الموارد البشرية ,شركة",
        opn:`أرى في مجتمع الموارد البشرية أحد أهم دعائم تطوير ممارسات الموارد البشرية في المملكة العربية السعودية. حيث يهتم نخبة من الشباب السعودي في خلق بيئة تفاعلية بين ممارسي الموارد البشرية - HR Practitioners - وذلك لمشاركة الخبرات العملية مع استغلال جيد لدور المنظومة التعليمية العليا. 
        طموحنا في استمرار المبادرات المقدمة من قبلهم وذلك لتتحول إلى مصدر لخلق المحتوى العربي الحديث بالإضافة إلى تكوين مصدر للبيانات القياسية الدقيقة benchmark data فيما يتعلق بإدارة الموارد البشرية والتي هي من ركائز تطوير أي منظومة. 
        `
    },
    {
        id:6,
        name:"د/فاتن آل ساري ",
        pos:"مستشارة وكيل وزارة الخدمة المدنية للتخطيط وتطوير الموارد البشرية",
        opn:`لا يختلف إثنان على أنّ الشّبابَ قوة يُمكن استثمارها في التنمية الشاملة، وهذا ما شهدته في مجتمع الموارد البشرية، الذي يقوده شباب وشابات سعوديات يعملون بكل إبداع للحصول على أفكار خلاقة،ويتمتعون بروح وطنية منتجهة تعمل على مبادرات صغيرة ذات أثر كبير ومستدام يضمن النّجاح والتقدّم في بيئه العمل بمختلف قطاعاتها.  تلك هي الروح التي وجدتها في مجتمع الموارد البشريه.. أتمنى لهم المزيد من النجاح والتقدم والعطاء.
        `
    }
]