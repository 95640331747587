import React from "react";
import pic2 from '../Assets/AdvisoryBoard/Picture2.jpg'
import pic3 from '../Assets/AdvisoryBoard/Picture3.png'
import pic4 from '../Assets/AdvisoryBoard/Picture4.png'
import pic5 from '../Assets/AdvisoryBoard/Picture5.jpg'
import pic6 from '../Assets/AdvisoryBoard/Picture6.jpg'
import pic9 from '../Assets/AdvisoryBoard/Picture9.jpg'
import pic10 from '../Assets/AdvisoryBoard/Picture10.png'
import pic11 from '../Assets/AdvisoryBoard/Picture11.jpg'
import pic12 from '../Assets/AdvisoryBoard/Picture12.jpg'
import pic13 from '../Assets/AdvisoryBoard/Picture13.jpg'
import pic15 from '../Assets/AdvisoryBoard/Picture15.jpg'
import pic14 from '../Assets/AdvisoryBoard/Picture14.png'
import pic16 from '../Assets/AdvisoryBoard/Picture16.png'
import pic18 from '../Assets/AdvisoryBoard/Picture18.jpg'
import pic19 from '../Assets/AdvisoryBoard/fb.jpeg'
import logo2 from '../Assets/ihrsp@2x.png';
export default function AdvisoryBoard() {
    return (
        <section className='AdvisoryBoard container mt-5'>
            <img style={{height:'150px',width:'250px',display:'block'}} src={logo2} alt="logo"/>
            <h2 className="main-title mb-4 ">الاعضاء</h2>
            <div className="row w-100">
                <div className='w-100 head  flex-wrap mb-0 mb-lg-3'>
                    <div className="col-12 col-md-4 mb-2 col-lg-2 px-2">
                        <div className='card-team heading2'>
                            <img src={pic2} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. فواز العمران</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='card-team heading2'>
                            <img src={pic3} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. عادل الغامدي</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic11} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>ماجدة سقدار</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic9} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>أ. محمد الشهري</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                    <div className='w-100 h-100 card-team heading2'>
                        <img src={pic6} alt='personal'/>
                        <div className='info'>
                            <p className='p-0 m-0 mb-2 text-center'>ا. فهد القميع</p>
                            <hr className='my-1'/>
                            <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="row w-100">
                <div className='w-100 head mb-0 mb-lg-3 flex-wrap'>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic10} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>م. ناصر الجريد</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic12} alt='personal'/>
                            <div className='info'>
                            <p className='p-0 m-0 mb-2 text-center'>أ. ابراهيم العامر</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div> 
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic13} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>د. رنا المصري</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 res-col col-md-4 col-lg-2 px-2">
                    <div className='w-100 h-100 card-team heading2'>
                        <img src={pic14} alt='personal'/>
                        <div className='info'>
                            <p className='p-0 m-0 mb-2 text-center'>ماجدة سقدار</p>
                            <hr className='my-1'/>
                            <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0  res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic16} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>أمل شيرة</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row w-100">
                <div className='w-100 head mb-0 mb-lg-3 flex-wrap'>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic18} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>أمل شيرة</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic4} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center'>نوف السفياني</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 mb-2 mb-lg-0 res-col col-md-4 col-lg-2 px-2">
                        <div className='w-100 h-100 card-team heading2'>
                            <img src={pic5} alt='personal'/>
                            <div className='info'>
                            <p className='p-0 m-0 mb-2 text-center'>د. شذى أبو زنادة</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center'>عضو</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-2  col-md-4 col-lg-2 px-2">
                    <div className='card-team heading2'>
                        <img src={pic19} alt='personal'/>
                        <div className='info'>
                            <p className='p-0 m-0 mb-2 text-center text-nowrap'>م. فارس البراك</p>
                            <hr className='my-1'/> 
                            <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

