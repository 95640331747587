import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TwitterEmbed } from 'react-social-media-embed';
import { dbtweets} from '../../firebase/firebasetweets';

export default function Tweetshandler() {
    const navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../../signup');
    }
    const [tweets,setTweets] = useState([]);
    const [data,setData] = useState({});
    useEffect(()=>{
        getData();
    },[]);
    const getData = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbtweets, "tweets"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setTweets([...mutli]);
        }catch(e){
            console.log(e);
        }
    }
    const submitHandle = async()=>{
        try{
            await addDoc(collection(dbtweets,"tweets"),{...data});
            setData({});
            getData();
        }catch(err){
            console.log(err);
        }
    };
    const deletehandler = async(eid)=>{
        try{
            await deleteDoc(doc(dbtweets, "tweets", eid));
            getData();
        }catch(e){
            console.log(e);
        }
    }
    return (
        <>
            <form onSubmit={(e)=>{
                e.preventDefault();
                submitHandle();
            }} className="form-bg m-3 py-3">
                <input required type={"text"} value={data.url!==undefined?data.url:""} className="form-control" placeholder="رابط التغريده" onChange={(e)=>{
                    setData({...data,url:`${e.target.value}`});
                }}/>
                <button type='submit' className={`btn btn-primary mt-2 w-auto`}>اضف تغريده جديده</button>
            </form>
            <section className='eventsHandle row mt-4'>
                {(tweets.length!==0)&&tweets.map((el)=>{
                    return(
                        <div key={el.id} className="col-4 custom-card px-2 handlers">
                            <i className="bi bi-x-square-fill" onClick={()=>{
                                deletehandler(el.id);
                            }}></i>
                            {(el.url!=='')&&<TwitterEmbed url={el.url} style={{height:"510px" , overflow:"auto"}}/>}
                        </div>
                    );
                })}
            </section>
        </>
    )
}

