import { collection, deleteDoc, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { useDownloadExcel } from 'react-export-table-to-excel';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { dbevents } from '../../firebase/firebaseevents';
export default function Eventsmembes() {
    let navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../../signup');
    }
    const [users,setUsers] = useState(undefined);
    const [eventes,setEvents] = useState([]);
    const [reload,setReload] = useState(true);
    const tableRef = useRef(null);
    const [filter,setFilter] = useState("all");
    useEffect(()=>{
        getData();
        getEvents();
    },[]);
    const deletehandler = async(eid)=>{
        try{
            await deleteDoc(doc(dbevents, "eventsform", eid));
        }catch(e){
            console.log(e);
        }
    }
    const getEvents = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbevents, "events"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setEvents([...mutli]);
        }catch(e){
            console.log(e);
        }
    }
    const getData = async()=>{
        try{
            if(filter==="all"){
                const  q = query(collection(dbevents, "eventsform"));  
                onSnapshot(q, (querySnapshot) => {
                    let mutli = [];
                    let counter = 0;
                    querySnapshot.forEach((doc) => {
                        mutli.push(<tr key={doc.id}>
                            <td><i className="bi bi-x-square-fill text-nowrap deleting" onClick={(e)=>{
                                deletehandler(doc.id,(doc.data()).img);
                            }}></i></td>
                            <td>{counter}</td>
                            <td>{(doc.data()).name}</td>
                            <td>{(doc.data()).orgnization}</td>
                            <td>{(doc.data()).position}</td>
                            <td>{(doc.data()).title}</td>
                            <td>{(doc.data()).phone}</td>
                            <td>{(doc.data()).email}</td>
                            <td><a className='text-primary bg-transparent border-0' target={"_blank"} href={(doc.data()).linkedin}><i className="bi bi-eye fs-5"></i></a></td>
                        </tr>);
                        counter++;
                    });
                    setUsers([...mutli]);
                }); 
            }else{
                const  q = query(collection(dbevents, "eventsform"),where("title","==",filter));  
                onSnapshot(q, (querySnapshot) => {
                    let mutli = [];
                    let counter = 0;
                    querySnapshot.forEach((doc) => {
                        mutli.push(<tr key={doc.id}>
                            <td><i className="bi bi-x-square-fill text-nowrap deleting" onClick={(e)=>{
                                deletehandler(doc.id,(doc.data()).img);
                            }}></i></td>
                            <td>{counter}</td>
                            <td>{(doc.data()).name}</td>
                            <td>{(doc.data()).orgnization}</td>
                            <td>{(doc.data()).position}</td>
                            <td>{(doc.data()).title}</td>
                            <td>{(doc.data()).phone}</td>
                            <td>{(doc.data()).email}</td>
                            <td><a className='text-primary bg-transparent border-0' target={"_blank"} href={(doc.data()).linkedin}><i className="bi bi-eye fs-5"></i></a></td>
                        </tr>);
                        counter++;
                    });
                    setUsers([...mutli]);
                }); 
            }
        }catch(e){
            console.log(e);
        }
    }
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'eventsMembers_table',
        sheet: 'eventsMembers'
    })
    return (
        <section className='container'>
            <button className='btn btn-primary ms-3' disabled={(users!==undefined&&users.length===0)?true:false} onClick={()=>{
                setReload(!reload);
            }}>اريد تحميل الجدول</button>
            <button className='btn btn-primary ms-auto' disabled={(users!==undefined&&users.length===0)?true:false} onClick={onDownload}>فتح فى اكسيل شيت</button>
            {(users!==undefined)?
                (users.length!==0)?<div className='w-100'>
                    {
                        (eventes.length!==0)&&<form className='d-flex justify-content-start align-items-center flex-wrap my-3' onSubmit={(e)=>{
                            e.preventDefault();
                            getData();
                        }}>
                            {[...eventes].map((elem)=>{
                                return(<div key={elem.id} className="form-check mx-4" >
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id={
                                        elem.title
                                    } onChange={(e)=>{
                                        setFilter(elem.title);
                                    }}/>
                                    <label className="form-check-label" htmlFor={elem.title}>
                                        {elem.title}
                                    </label>
                                </div> );
                            }) }
                            <div className="form-check mx-4" >
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id={
                                        "كل الفعاليات"
                                    } onChange={(e)=>{
                                        setFilter("all");
                                    }}/>
                                    <label className="form-check-label" htmlFor={"كل الفعاليات"}>
                                        {"كل الفعاليات"}
                                    </label>
                            </div> 
                            <button type='submit' className={`btn btn-primary mt-2 w-auto`} >تصفيه</button>
                        </form>
                    }
                    <div className="table-responsive mt-3">
                            <table  ref={tableRef} className="table table-secondary">
                                <thead>
                                    <tr>
                                        <th>امسح</th>
                                        <th className='text-nowrap'>#رقم</th>
                                        <th className='text-nowrap'><i className="bi bi-person-fill"></i> الاسم</th>
                                        <th className='text-nowrap'><i className="bi bi-person-fill"></i> اسم المنظمة</th>
                                        <th className='text-nowrap'><i className="bi bi-person-fill"></i> المسمى الوظيفي</th>
                                        <th className='text-nowrap'><i className="bi bi-person-fill"></i>الفعاليه</th>
                                        <th className='text-nowrap'><i className="bi bi-person-fill"></i>رقم الهاتف</th>
                                        <th className='text-nowrap'><i className="bi bi-flag-fill"></i> البريد الالكترونى</th>
                                        <th className='text-nowrap'>لينكدان</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users}
                                </tbody>
                            </table>
                    </div>
                </div>
                :<div>لا يوجد مشاريكين الان</div>:<div className='w-100 mt-5 d-flex justify-content-center align-items-center'>
                    <ReactLoading type={"spinningBubbles"} color={"#1884c2"} height={150} width={150} />
                </div>
            }
        </section>
    )
}

