import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const membersfirebaseConfig = {
    apiKey: "AIzaSyB-14zgqAlctBWyUJrYwLCzTEF2opzTh5c",
    authDomain: "ihrmembers-eafb0.firebaseapp.com",
    projectId: "ihrmembers-eafb0",
    storageBucket: "ihrmembers-eafb0.appspot.com",
    messagingSenderId: "75262300",
    appId: "1:75262300:web:ca87d1ca7dcf03fe8d7999",
    measurementId: "G-VEVDP1SB3Y"
};

// Initialize Firebase
const appmembers = initializeApp(membersfirebaseConfig, "Members");

export const authmembers = getAuth(appmembers);

export const dbmembers = getFirestore(appmembers);

export const storagemembers = getStorage(appmembers);