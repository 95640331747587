import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { dbmembers, storagemembers } from '../../firebase/firebasemembers';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import logo from '../../Assets/ihrsp@2x.png';


export default function MemberForm() {
    const {id} = useParams();
    const param = Number(id);
    const arr = [
        {
            type:'عضويه منتسب',
            price:'150 ريال'
        },
        {
            type:'عضويه عامل',
            price:'250 ريال'
        },
        {
            type:'عضويه طالب',
            price:'100 ريال'
        },
        {
            type:'العضويه الماسيه',
            price:'7000 ريال'
        },
        {
            type:'العضويه الماسيه',
            price:'7000 ريال'
        },
        {
            type:'العضويه الذهبيه',
            price:'4500 ريال'
        },
        {
            type:'العضويه الفضيه',
            price:'3000 ريال'
        },
        {
            type:'العضويه الاساسيه',
            price:'1500 ريال'
        },
    ]
    const type = arr[param].type;
    const price = arr[param].price;
    const [data,setData] = useState({
        national:"سعودى",
        currentBusiness:"لا يوجد",  
        gender:"ذكر",
        type:type,
        price:price,
    });
    const navigator = useNavigate();
    const [done,setDone] = useState(false);
    const [prog,setProg] = useState(0);
    const [uploaded,setUploade] = useState(false);
    const [url,setUrl] = useState('');
    const handleImage = async(file)=>{
        const displayNAme = new Date().getTime() + file.name;
        const storageRef  = ref(storagemembers, displayNAme);
        const uploadTask  = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProg(progress);
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, 
            (error) => {
                console.log(error);
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setData({...data,img:`${downloadURL}`});
                    setUploade(true);
                });
            }
        );
    }
    const handleSubmit = async()=>{
        try{
            if(data.img!==undefined){
                await addDoc(collection(dbmembers,"memberships"),{...data,timeStamp:serverTimestamp()});
                setData({});
                setDone(true);
                let handler = setTimeout(()=>{
                    navigator(0);
                    clearTimeout(handler);
                },1000);
            }
        }catch(err){
            console.log(err);
        }
    };
    return (
        <section className='container mt-4'>
            <div className='formmember'>
                <img style={{height:'100px',width:'auto'}} src={logo} alt="logo"/>
                <h5 className='title text-right my-2'>كيف تتنضم الينا؟</h5>
                <p className='text-right'><i className="bi bi-1-circle-fill ms-2 text-right"></i>يرجى تحويل رسوم العضويه الى بيانات الحساب التالى:</p>
                <p className='text-right me-4'><i className="bi bi-bank ms-1 text-primary"></i>اسم البنك: البنك البلاد</p>
                <p className='text-right me-4'><i className="bi bi-bookmark-star-fill ms-1 text-primary"></i>شركة النقط الثلاث للتقنية والتدريب</p>
                <p className='text-right me-4'><i className="bi bi-cash-coin ms-1 text-primary"></i>رقم الحساب البنكي: S A 4 1 1 5 0 0 0 4 3 0 1 2 8 8 7 4 1 7 0 0 1 1</p>
                <p className='text-right'><i className="bi bi-2-circle-fill ms-2 text-right"></i> يرجى الإحتفاط بصورة أو نسخة من ايصال الحوالة حتى تتمكن من ارفاقه بطلب التسجيل .</p>
                <p className='text-right'><i className="bi bi-3-circle-fill ms-2 text-right"></i>يتم ملئ طلب التسجيل ومبارك عليك العضويه</p>
                <p className='title text-right my-2'>مراجعه البيانات :</p>
                <p className='text-right'><i className="bi bi-1-circle-fill ms-2 text-right"></i>نوع العضويه:  {type}</p>
                <p className='text-right'><i className="bi bi-2-circle-fill ms-2 text-right"></i>السعر: {price}</p>
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    handleSubmit();
                }}>
                <p className='custom-header p-1 title my-2'>رفع ايصال الدفع</p>
                <p className='text-danger text-end'>*يرجى رفع ايصال الدفع اولا*</p>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label text-right w-100">اضف الايصال<span>*</span></label>
                        <input  required className="form-control mb-2" type="file" id="formFile" onChange={(e)=>{
                            const src = URL.createObjectURL(e.target.files[0]);
                            handleImage(e.target.files[0]);
                            setUrl(src);
                        }}/>
                        {<ProgressBar
                        percent={prog}
                        filledBackground="#1884c2"
                        />}
                    </div>
                    {(uploaded)&&<img className='isal p-1 mb-1' src={url} width={"auto"} height={"250px"}/>}
                    <p className='custom-header p-1 title my-4'>المعلومات الشخصيه</p>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='ms-0 d-flex justify-content-center align-items-start flex-column'>
                            <label>الاسم<span>*</span></label>
                            <input  disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                                setData({...data,name:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>النوع<span>*</span></label>
                            <select disabled={!uploaded&&true} disabled={!uploaded&&true} className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                                setData({...data,gender:e.target.value});
                            }}>
                                <option value="ذكر">ذكر</option>
                                <option value="أنثى">أنثى</option>
                            </select>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='ms-0 d-flex justify-content-center align-items-start flex-column'>
                            <label>رقم الجوال<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"tel"} inputMode={"tel"}  data-key="mobile_number" onChange={(e)=>{
                                setData({...data,phone:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>المدينة<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100" type={'text'} id="city" name="city" autoComplete='address-level2' required="" enterKeyHint='next' onChange={(e)=>{
                                setData({...data,city:e.target.value});
                            }}/>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='ms-0 d-flex justify-content-center align-items-start flex-column'>
                            <label>تاريخ الميلاد<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"date"} onChange={(e)=>{
                                setData({...data,pirthdate:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>الجنسيه<span>*</span></label>
                            <select disabled={!uploaded&&true} className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                                setData({...data,national:e.target.value});
                            }}>
                                <option value="سعودى">سعودى</option>
                                <option value="غير سعودى">غير سعودى</option>
                            </select>
                        </div>
                    </div>
                    <p className='custom-header p-1 title my-4 '>المعلومات الوظيفة</p>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='ms-0 d-flex justify-content-center align-items-start flex-column'>
                                <label>المسمى الوظيفي (بالعربي)<span>*</span></label>
                                <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                                    setData({...data,JobTitle:e.target.value});
                                }}/>
                        </div>
                        <div className=' d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>قطاع العمل الحالي<span>*</span></label>
                            <select disabled={!uploaded&&true} className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                                setData({...data,currentBusiness:e.target.value});
                            }}>
                                <option value="لا يوجد">لا يوجد</option>
                                <option value="حكومى">حكومى</option>
                                <option value="شبه حكومى">شبه حكومى</option>
                                <option value="خاص">خاص</option>
                            </select>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='d-flex justify-content-center align-items-start flex-column'>
                                <label>جهة العمل (بالعربي)<span>*</span></label>
                                <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                                    setData({...data,Employer:e.target.value});
                                }}/>
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-center align-items-start flex-column px-1'>
                        <label>الإهتمامات والمهارات<span>*</span></label>
                        <textarea disabled={!uploaded&&true} placeholder='تحدث عن مهاراتك واهتماماتك' className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                            setData({...data,intersts:e.target.value});
                        }}/>
                    </div>  
                    <p className='custom-header p-1 title my-4'>معلومات التواصل</p>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='ms-0 d-flex justify-content-center align-items-start flex-column'>
                            <label>البريد الالكترونى<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"email"} onChange={(e)=>{
                                setData({...data,email:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>حسابك فى linkedin<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"url"} onChange={(e)=>{
                                setData({...data,linkdin:e.target.value});
                            }}/>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'> 
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>حسابك فى twitter<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"url"} onChange={(e)=>{
                                setData({...data,twitter:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>حسابك فى snapshott<span>*</span></label>
                            <input disabled={!uploaded&&true} className="rounded p-1 mt-2 w-100 px-1" required type={"url"} onChange={(e)=>{
                                setData({...data,snapshott:e.target.value});
                            }}/>
                        </div>
                    </div>
                    <button type="submit" disabled={!uploaded&&true} className="btn mt-3 w-50 w-lg-25 btn-primary">طلب انضمام</button>
                </form>
            </div>
            <div className={`massage ${!done&&"active"}`}>
                <i className="bi bi-x-square-fill" onClick={()=>{setDone(false)}}></i>
                تم إرسال طلب العضوية بنجاح ، سيتم دراسة الطلب من قبل الفريق والرجوع إليكم قريباً ، شكراً لكم!
            </div>
        </section>
        
    )
}
