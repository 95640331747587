import React from 'react'
import logo2 from '../../Assets/ihrsp@2x.png';
import linkedimage from '../../Assets/linkedin.jpeg';
import twiterimage from '../../Assets/twitimage.jpg';
import youtube from '../../Assets/youtubeimage.jpg';
import snapimages from '../../Assets/snapimages.jpg';
export default function Footer() {
    return (
        <>
            <footer className='footer mt-5'>    
                <div className='px-2 w-100  d-flex justify-content-between align-items-center'>
                    <img style={{height:'100px',width:'auto',display:'block'}} src={logo2} alt="logo"/>
                    <p className='mb-1 w-100 custom-f  d-none d-md-block'>Copyright © 2023 iHR Saudi Platform</p>
                    <div className='sociallinks'>
                        <a target={"_blank"} href='https://www.linkedin.com/in/ihr-saudi-community'>
                            <img src={linkedimage} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                        </a>
                        <a target={"_blank"} href='https://twitter.com/ihrsaudi'>
                            <img src={twiterimage} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                        </a>
                        <a target={"_blank"} href='https://www.youtube.com/channel/UCyaY-WfBPKGK2HUHND1GGRw/featured'>
                            <img src={youtube} alt="imagelinked" className='ms-3' style={{width:"30px",height:"25px",borderRadius:"5px"}}/>
                        </a>
                        <a target={"_blank"} href='https://www.snapchat.com/add/ihr.saudi'>
                            <img src={snapimages} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                        </a>
                    </div>
                </div>
                <p className='mb-1 w-100 custom-f  d-block d-md-none'>Copyright © 2023 iHR Saudi Platform</p>
            </footer>
        </>
    )
}
