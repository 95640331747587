import React from 'react'

export default function Privacy() {
    return (
        <section className='privacy container mt-4'>
            <p className=''>التزامنا بمراعاة خصوصيتك</p>
            <div className='row w-100'>
                <article className='my-2'><i className="bi bi-1-circle-fill ms-2"></i>تضع المنصة السعودية آي.اتش.آر سرية معلومات مستخدميها وزوارها على رأس قائمة الأولويات، وتبذل إدارة المنصة كل جهودها لتقديم خدمة ذات جودة عالية لكل المستفيدين، وتعتبر الخصوصية وسرية المعلومات الموضحة ادناه جزءاً من شروط استخدام البوابة الإلكترونية للمنصة وأحكامها</article>
                <article className='my-2'><i className="bi bi-2-circle-fill ms-2"></i>لا تقوم المنصة السعودية آي.اتش.آر بجمع معلومات شخصية عنك عندما تقوم بزيارة بوابتها إلا إذا اخترت تحديداً وبمعرفتك تقديم هذه المعلومات لنا. إذا اخترت تقديم معلوماتك، فإننا لا نستخدمها إلا لإنجاز طلبك للحصول على معلومات أو خدمات وفق ما ورد في هذه السياسات، وباستخدامك المنصة فإنك توافق على سياسة الخصوصية وسرية المعلومات هذه.، يجب على زوار البوابة والمستفيدين من.</article>
                <article className='my-2'><i className="bi bi-3-circle-fill ms-2"></i>لا تقوم المنصة السعودية آي.اتش.آر بجمع معلومات شخصية عنك عندما تقوم بزيارة بوابتها إلا إذا اخترت تحديداً وبمعرفتك تقديم هذه المعلومات لنا. إذا اخترت تقديم معلوماتك، فإننا لا نستخدمها إلا لإنجاز طلبك للحصول على معلومات أو خدمات وفق ما ورد في هذه السياسات، وباستخدامك المنصة فإنك توافق على سياسة الخصوصية وسرية المعلومات هذه.، يجب على زوار البوابة والمستفيدين من.</article>
            </div>
            <p className='mt-3'>البيانات الشخصية</p>
            <div className='row w-100'>
                <p className='mb-y text-right text-cuto'>ما هي المعلومات الشخصية التي نجمعها؟</p>
                <p className='mb-y text-right text-cuto'>"المعلومات الشخصية" هي المعلومات التي تعرّف عنكم كأفراد، وتشمل:</p>
                <ul className='px-5'>
                    <li>الاسم</li>
                    <li>اسم المستخدم</li>
                    <li>رقم الهاتف</li>
                    <li>عنوان البريد الإلكتروني</li>
                    <li>رقم بطاقة الاعتماد</li>
                    <li>رقم البطاقة</li>
                    <li>الصورة الشخصية</li>
                    <li>رقم الحساب على وسائل التواصل الاجتماعي</li>
                    <li>بلد الإقامة</li>
                    <li>اسم وعنوان الشركة</li>
                    <li>الدورات التدريبية المفضّلة</li>
                    <li>التفضيلات</li>
                    <li>معلومات حول المتصفّح والجهاز</li>
                    <li>معلومات حول ملفّ سجلّ الخادم</li>
                    <li>المعلومات التي يتم جمعها من خلال ملفات تعريف الارتباط وبيانات البكسل الإضافية والتقنيات الأخرى</li>
                    <li>بيانات استخدام التطبيقات</li>
                    <li>معلومات الموقع</li>
                </ul>
            </div>
            <p className='mt-3'>جمع البيانات الشخصيه</p>
            <div className='row w-100'>
                <p className='mb-y text-right text-cuto'>كيف نجمع المعلومات الشخصية؟</p>
                <p className='mb-y text-right text-cuto'>قد نقوم نحن ومقدمو الخدمات لدينا بجمع معلومات شخصية بأساليب مختلفة، تشمل ما يلي:</p>
                <article className='my-2'><i className="bi bi-1-circle-fill ms-2"></i>قد نقوم نحن ومقدمو الخدمات لدينا بجمع معلومات شخصية بأساليب مختلفة، تشمل ما يلي:</article>
                <article className='my-2'><i className="bi bi-2-circle-fill ms-2"></i>من خلال ملفات سجل الخادم: إنّ "عنوان بروتوكول الإنترنت" الخاص بكم هو رقم يُخصّص تلقائياً للحاسوب أو الجهاز الذي تستخدمونه من قبل موفّر خدمة الإنترنت الخاص بكم. ويتمّ تحديد عنوان بروتوكول الإنترنت وتسجيله تلقائيًا في ملفات سجل الخادم كلّما قام المستخدم بزيارة الموقع، مع تحديد وقت الزيارة والصفحة/الصفحات التي جرت زيارتها.</article>
                <article className='my-2'><i className="bi bi-3-circle-fill ms-2"></i>من خلال استخدامكم للتطبيق: عند تنزيل أو استخدام أحد التطبيقات الخاصة بنا ، يمكننا نحن ومقدّمو الخدمات لدينا أن نتتبّع ونجمع بيانات استخدام التطبيق، مثل وقت وتاريخ دخول التطبيق الموجود على جهازكم إلى خوادمنا والمعلومات والملفات التي تم تنزيلها على التطبيق بالاعتماد على رقم جهازكم</article>
                <article className='my-2'><i className="bi bi-4-circle-fill ms-2"></i>من خلالكم: يمكن جمع معلومات مثل تاريخ الولادة والجنس والرمز البريدي، بالإضافة إلى غيرها من المعلومات، مثل وسائل الاتصّال المفضلة لديكم، عندما تقومون أنتم بتوفير هذه المعلومات طوعًا والمذكورةأعلاه</article>
            </div>
            <p className='mt-3'>استخدام البيانات الشخصية</p>
            <div className='row w-100'>
                <p className='mb-y text-right text-cuto'>كيف نستخدم المعلومات الشخصية؟</p>
                <p className='mb-y text-right text-cuto'>قد نستخدم المعلومات الشخصية:</p>
                <article className='my-2'><i className="bi bi-1-circle-fill ms-2"></i>·   للرد على استفساراتكم وتلبية طلباتكم، مثل إرسال النشرات الإخبارية لكم أو الإجابة على أسئلتكم والرد على تعليقاتكم.
                ·   لإرسال معلومات إدارية لكم، مثل المعلومات المرتبطة بالمواقع والتعديلات التي تطرأ على شروطنا وأحكامنا وسياساتنا. وبما أنّ هذه المعلومات قد تكون مهمّة لاستخدامكم المواقع، لا يمكنكم التوقف عن تلقّي هذه الرسائل.
                </article>
                <article className='my-2'><i className="bi bi-2-circle-fill ms-2"></i>لاستكمال وإنجاز عمليات الشراء مثل معالجة مدفوعاتكم، التواصل معكم فيما يخصّ عضويتكم وتوفير خدمة العملاء المناسبة لكم.</article>
                <article className='my-2'><i className="bi bi-3-circle-fill ms-2"></i>·   لإرسال التحديثات والإعلانات المتعلقة بمنتجاتنا وحملاتنا الدعائية وبرامجنا، وإرسال دعوات لكم للمشاركة في تقييمها.</article>
                <article className='my-2'><i className="bi bi-4-circle-fill ms-2"></i>·   لإرسال التحديثات والإعلانات المتعلقة بمنتجاتنا وحملاتنا الدعائية وبرامجنا، وإرسال دعوات لكم للمشاركة في تقييمها.</article>
                <article className='my-2'><i className="bi bi-5-circle-fill ms-2"></i>·   للسماح لكم بالمشاركة في لوحات الرسائل والدردشات وصفحات الملفات الشخصية وسجلات خادم الويب (المدونات الإلكترونية) وغيرها من الخدمات التي يمكنكم نشر المعلومات والمواد عليها (بما فيها الصفحات على وسائل التواصل الاجتماعي).</article>
                <article className='my-2'><i className="bi bi-6-circle-fill ms-2"></i>لأغراض أعمالنا مثل تحليل وإدارة أعمالنا، وإجراء دراسات السوق، ومراجعة الحسابات، وتطوير منتجات جديدة، وتعزيز مواقعنا، وتحسين خدماتنا ومنتجاتنا، وتحديد اتجاهات الاستخدام، وتحديد فعالية حملاتنا الترويجية، وتصميم تجربة ومحتويات المواقع خصيصًا بالاعتماد على نشاطاتكم السابقة على المواقع وتفضيلاتكم الخاصة بكم ، بالإضافة إلى قياس رضا العملاء، وتوفير خدمة العملاء (بما فيها استكشاف الأخطاء وإصلاحها فيما يتعلّق بقضايا العملاء).</article>
                <article className='my-2'><i className="bi bi-7-circle-fill ms-2"></i>·   نستخدم المعلومات التي يوفرها المتصفح أو جهازكم لأغراض الإحصائيات وضمان حسن عمل المواقع.</article>
                <article className='my-2'><i className="bi bi-8-circle-fill ms-2"></i>إذا كانت لديكم أي أسئلة حول طريقة معالجة iHR  لبياناتكم الشخصية أو كنتم ترغبون في الحصول على نسخة من معلوماتكم الموجودة بحوزة iHR ، أو تريدون تصحيح أو محو أي بيانات شخصية غير دقيقة، نرجو منكم الاتصال بنا.</article>
            </div>
            <p className='mt-3'>سياسات هامه</p>
            <div className='row w-100'>
                <p className='mb-y text-right text-cuto'>الدخول والتعديل والخيارات</p>
                <article className='my-2'><i className="bi bi-1-circle-fill ms-2"></i>من أجل مراجعة أو تصحيح أو تحديث أو إلغاء أو محو أو الحد بأي طريقة أخرى من استخدامنا لمعلوماتكم الشخصية التي سبق وقمتم بتوفيرها لنا، سنحاول الاستجابة لطلبكم في أسرع وقت ممكن. ولكن، وعلى الرغم من جهودنا، قد يبقى بعض المعلومات ضمن قاعدات بياناتنا أو سجلاتنا الأخرى والتي لا يمكننا إزالتها أو تغييرها. علاوةً على ذلك، نرجو منكم التنبه إلى أنّنا قد نحتاج إلى الاحتفاظ ببعض المعلومات لأغراض الحفظ و/أو لاستكمال أي معاملة بدأتم بها قبل طلب إجراء هذا التغيير أو الإلغاء (مثال: عندما تقومون بعملية شراء، قد لا تستطيعون تغيير أو مسح المعلومات الشخصية التي سبق توفيرها إلى حين استكمال عملية الشراء)</article>
                <p className='mb-y text-right text-cuto'>تحديثات سياسة حماية الخصوصية</p>
                <article className='my-2'><i className="bi bi-1-circle-fill ms-2"></i>
                كما على المستفيد الاطلاع بشكل مستمر على شروط ومبادئ الخصوصية وسرية المعلومات لمعرفة أية تحديثات تتم عليها، علماً بأن إدارة المنصة غير مطالبة بالإعلان عن أية تحديثات تتم على تلك الشروط والمبادئ، ويعني استخدامك للمنصة اطلاعك وموافقتك على تلك الشروط والمبادئ وما يتم عليها من تعديلات مستمرة.
                </article>
            </div>
        </section>
    )
}
