import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Default() {
    const navigator = useNavigate();
    navigator('');
    return (
        <div>
        
        </div>
    )
}
