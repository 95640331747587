import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { collection, getDocs } from 'firebase/firestore';
import { dbnews } from '../../firebase/firebasenews';
export default function News() {
    const [nwes,setNwes] = useState([]);
    useEffect(()=>{
        getData();
    },[]);
    const getData = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbnews, "nwes"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setNwes([...(mutli)]);
        }catch(e){
            console.log(e);
        }
    }
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        infinite: nwes.length>2?true:false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: nwes.length>2?true:false,
                    dots: true
                }
            },{
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite:true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite:true,
                }
            }
        ]
    };
    return(
        <section className='mt-4 mt-lg-5 events container'>
            <h2 className="main-title mb-lg-4 mb-1 ">اخر الاخبار</h2>
            {(nwes.length!==0)?
                <Slider {...settings}>
                    {nwes.map((item)=>{
                        const style = {
                            objectFit:"contain",
                            objectPosition: "center",
                            width: "100%",
                            maxHeight:"450px"
                            // height: "600px",
                        }
                        return(
                            <div key={item.id} className="w-100 custom-card px-2">
                                <img className='responsive-image' style={{...style}} src={item.img} alt='iageCa' />
                                <p className='my-2 fs-6 text-right'>{item.title}</p>
                                <p className='text-right'>{item.body}</p>
                            </div>
                        )
                    })}
                </Slider>:<p>لا يوجد فعاليات الان</p>}
        </section>
    )
}
