import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const tweetsProjectFirebaseConfig = {
    apiKey: "AIzaSyBZqaw_wIlkYD7dBD9aV8zg6ZI4TvAXJig",
    authDomain: "ihrtweet.firebaseapp.com",
    projectId: "ihrtweet",
    storageBucket: "ihrtweet.appspot.com",
    messagingSenderId: "632204579819",
    appId: "1:632204579819:web:afbeb56a5a6bf435ba7fae",
    measurementId: "G-Y98LCWD76M"
};

// Initialize Firebase
const apptweets = initializeApp(tweetsProjectFirebaseConfig, "TWEETS");

export const authtweets = getAuth(apptweets);

export const dbtweets = getFirestore(apptweets);

export const storagetweets = getStorage(apptweets);