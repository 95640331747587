import React from 'react'
import { Link } from 'react-router-dom'

export default function Services() {
    return (
        <section className='services container mt-4'>
            <p className='fs-lg-5 fs-6 m-0 p-1 p-lg-2 mb-2 text-center cover'>حديث التخرج خبره اقل من سنيتين</p>
            <div className='row'>
                <div className='col-12 col-lg-4 mb-2 px-2'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-person-fill ps-1 ms-2"></i>
                            باقه الاستشارات
                        </div>
                        <div className="card-body">
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>التواصل مع المشترك لابراز نقاط القوة.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>التدريب على المقابلات الشخصية</p>
                            <p className='p-0 m-0 mb-1 card-text'>350 ريال سعودى</p>
                            <Link to={"Services/0"} className='btn btn-primary'>اشترك الان</Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 mb-2 px-2'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-person-fill ps-1 ms-2"></i>
                            الباقة الاقتصادية
                        </div>
                        <div className="card-body">
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء سيرة ذاتية باللغة الإنجليزية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء صفحة لينكد إن احترافية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>إرسال السيرة الذاتية إلى شركات وجهات التوظيف.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>سيرة ذاتية موشن جرافيك مدة 50 ثانية (اختياري) + 500 ريال</p>
                            <p className='p-0 m-0 mb-1 card-text'>400 ريال سعودى</p>
                            <Link to={"Services/1"} className='btn btn-primary'>اشترك الان</Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 mb-2 px-2'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-person-fill ps-1 ms-2"></i>
                            الباقة الشـــاملة
                        </div>
                        <div className="card-body">
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>التواصل مع المشترك لابراز نقاط القوة</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i> التدريب على المقابلات الشخصية</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء سيرة ذاتية باللغة الإنجليزية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء صفحة لينكد إن احترافية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>التدريب على كيفية التسويق من خلال برنامج لينكد إن</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>إرسال السيرة الذاتية إلى شركات وجهات التوظيف.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>سيرة ذاتية موشن جرافيك مدة 50 ثانية (اختياري) + 500 ريال</p>
                            <p className='p-0 m-0 mb-1 card-text'>800 ريال سعودى</p>
                            <Link to={"Services/2"} className='btn btn-primary'>اشترك الان</Link>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <p className='fs-5 m-0 p-2 my-2 text-center cover'>خبره اكثر من سنتين</p>
            <div className='row'>
                <div className='col-12 col-lg-4 mb-2 px-2'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-person-fill ps-1 ms-2"></i>
                            الباقة الاقتصاديه
                        </div>
                        <div className="card-body">
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء سيرة ذاتية باللغة الإنجليزية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء صفحة لينكد إن احترافية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>إرسال السيرة الذاتية إلى شركات وجهات التوظيف.</p>
                            <p className='p-0 m-0 mb-1 card-text'>800 ريال سعودى</p>
                            <Link to={"Services/3"} className='btn btn-primary'>اشترك الان</Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 mb-2 px-2'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-person-fill ps-1 ms-2"></i>
                            الباقة الشامله
                        </div>
                        <div className="card-body">
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>تقييم الاهتمامات ، الدوافع ، السلوك ، نقاط القوة ، المسارات المهنية مع جلسة استشارية مع مستشار مهني.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء سيرة ذاتية باللغة الإنجليزية بناء على تقرير المستشار وإضافة نقاط القوة لدى المرشح.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء صفحة لينكد إن احترافية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>إرسال السيرة الذاتية إلى شركات وجهات التوظيف.</p>
                            <p className='p-0 m-0 mb-1 card-text'>1500 ريال سعودى</p>
                            <Link to={"Services/4"} className='btn btn-primary'>اشترك الان</Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 mb-2 px-2'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-person-fill ps-1 ms-2"></i>
                            الباقة الشامله بلس
                        </div>
                        <div className="card-body">
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>تقييم الاهتمامات ، الدوافع ، السلوك ، نقاط القوة ، المسارات المهنية مع جلسة استشارية مع مستشار مهني.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء سيرة ذاتية باللغة الإنجليزية بناء على تقرير المستشار وإضافة نقاط القوة لدى المرشح.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>بناء صفحة لينكد إن احترافية.</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>ارسال السيره الذاتيه للشركات و جهات التوظيف</p>
                            <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>تقديم استشارات في المسار المهني وكيفية تطويره.</p>
                            <p className='p-0 m-0 mb-1 card-text'>2200 ريال سعودى</p>
                            <Link to={"Services/5"} className='btn btn-primary'>اشترك الان</Link>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="card mt-lg-4 flex-column flex-lg-row">
                <h5 className="card-header">
                    <i className="bi bi-person-fill ps-1 ms-2"></i>
                    الباقة الاستشارات
                </h5>
                <div className="card-body">
                    <p className='p-0 m-0 mb-1 card-text'><i className="bi bi-check2 ms-1"></i>تقديم استشارات في المسار المهني وكيفية تطويره.التواصل مع المشترك لإبراز نقاط القوة .</p>
                    <p className='p-0 m-0 mb-1 card-text'>700 ريال سعودى</p>
                    <Link to={"Services/6"} className='btn btn-primary'>اشترك الان</Link>
                </div>
            </div>
        </section>
    )
}
