import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import pic1 from '../Assets/AdministrativeTeam/fb.jpeg';
import pic2 from '../Assets/AdministrativeTeam/yas.jpeg';
import pic3 from '../Assets/AdministrativeTeam/Ael3.jpeg';
import pic4 from '../Assets/AdministrativeTeam/bshra.jpeg';
import pic5 from '../Assets/AdministrativeTeam/Picture5.jpg';
import pic6 from '../Assets/AdministrativeTeam/bian.jpeg';
import pic7 from '../Assets/AdministrativeTeam/rs.jpeg';
import pic8 from '../Assets/AdministrativeTeam/sm.jpeg';
import pic9 from '../Assets/AdministrativeTeam/nbg.jpeg';
import pic10 from '../Assets/AdministrativeTeam/hd.jpeg';
import pic11 from '../Assets/AdministrativeTeam/ns.jpeg';
import pic14 from '../Assets/AdministrativeTeam/Picture14.jpg';
import logo2 from '../Assets/ihrsp@2x.png';
import linkedimage from '../Assets/linkedin.jpeg';
export default function AdministrativeTeam() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className='AdministrativeTeam container mt-5'>
            <img style={{height:'150px',width:'250px',display:'block'}} src={logo2} alt="logo"/>
            <p className='stork2 fs-3 w-100'>الاعضاء</p>
            <Slider {...settings} className='w-100'>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic1} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>م. فارس البراك</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>المؤسس</p>
                            </div>
                            <a href="https://www.linkedin.com/in/faresalbarrak/">
                            <img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic2} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. ياسر السديس</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>المدير الإداري</p>
                            </div>
                            <a href="https://www.linkedin.com/in/yasser-alsudais/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team '>
                            <img src={pic3} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. أوان العبيد</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/awan-alobaid/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic4} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. بشرى الحربي</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/bushra-alharbi/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic5} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. وفاء الوافي</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/wafa-alwafi/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic6} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. بيان الملا</p>
                                <hr className='my-1'/> 
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/bayan-almulla/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic7} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. رباب السلطان</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/rabab-al-sultan/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team '>
                        <img src={pic8} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. زكريا المرشود</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/zakaria-almarshud/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic9} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. نوف بن جبير</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center  text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/nouf-binjubair/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team '>
                            <img src={pic10} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. حنان الدرويش</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/hanan-aldarweesh-1166818a/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic11} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. نورة السلوم</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/noura-alsalloum-/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic14} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. الهنوف الحواس</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/alhanoufalhawas/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
                    <div className="custom-card px-2">
                        <div className='card-team'>
                            <img src={pic14} alt='personal'/>
                            <div className='info'>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>أ. دلال الحارثي</p>
                                <hr className='my-1'/>
                                <p className='p-0 m-0 mb-2 text-center text-nowrap'>عضو</p>
                            </div>
                            <a href="https://www.linkedin.com/in/dalal-alharthi/"><img src={linkedimage} alt="imagelinked" style={{width:"35px",height:"35px",borderRadius:"5px"}}/></a>
                        </div>
                    </div>
            </Slider>
        </section>
    )
}
