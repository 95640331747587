import React,{useState} from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from "react-router-dom";

export default function Membershipscompanys() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
            <section className='companys container my-5'>
                <p className='fs-3'>عضويات مؤسسيه</p>
                <Slider {...settings} style={{overflow:"unset"}} className='w-100'>
                    <div className='card-aod col-4 px-2'>
                        <div className="mas">
                            <h5>العضويه الماسيه</h5>
                            <p className='py-2 m-0'>7000 ريال</p>
                            <p className='p-0 m-0'>بلا حدود</p>
                            <Link to={"Memberships/3"} className='mt-2 btn btn-secondary text-white'>انضم الان</Link>
                            <hr className='h-r' />
                            <p className='py-2 fs-4 m-0'>مزايا العضويه</p>
                            <ul className='advantages px-3'>
                                <li className="mb-2">الحصول على جميع مزايا المذكورة في عضوية المنتسب للممارسين/الممارسات.</li>
                                <li className="mb-2">20 عضوية منتسب</li>
                                <li className="mb-2">وضع الشعار في جميع منصات وإعلانات المنصة</li>
                                <li className="mb-2">إمكانية الإعلان عن الشواغر لا محدودة</li>
                                <li className="mb-2">إمكانية استعراض سير ذاتية موصى عليها من قبل أعضاء المنصة</li>
                                <li className="mb-2">إمكانية مشاركة تقارير التجارب والمبادرات على المنصة</li>
                                <li className="mb-2">الرسوم 7000 ريال سعودي</li>
                            </ul>
                        </div>
                    </div>
                    <div className='card-aod col-4 px-2'>
                        <div className="gold">
                            <h5>العضويه الذهبيه</h5>
                            <p className='py-2 m-0'>4500 ريال</p>
                            <p className='p-0 m-0'>بلا حدود</p>
                            <Link to={"Memberships/4"} className='mt-2 btn btn-secondary text-white'>انضم الان</Link>
                            <hr className='h-r' />
                            <p className='py-2 fs-4 m-0'>مزايا العضويه</p>
                            <ul className='advantages px-3'>
                                <li className="mb-2">الحصول على جميع مزايا المذكورة في عضوية المنتسب للممارسين/الممارسات.</li>
                                <li className="mb-2">13 عضوية منتسب</li>
                                <li className="mb-2">وضع الشعار في جميع منصات وإعلانات المنصة</li>
                                <li className="mb-2">إمكانية الإعلان عن الشواغر لا محدودة</li>
                                <li className="mb-2">إمكانية استعراض سير ذاتية موصى عليها من قبل أعضاء المنصة</li>
                                <li className="mb-2">إمكانية مشاركة تقارير التجارب والمبادرات على المنصة</li>
                                <li className="mb-2">الرسوم 4500 ريال سعودي</li>
                            </ul>
                        </div>
                    </div>
                    <div className='card-aod col-4 px-2'>
                        <div className="fada">
                            <h5>العضويه الفضيه</h5>
                            <p className='py-2 m-0'>3000 ريال</p>
                            <p className='p-0 m-0'>بلا حدود</p>
                            <Link to={"Memberships/5"} className='mt-2 btn btn-secondary text-white'>انضم الان</Link>
                            <hr className='h-r' />
                            <p className='py-2 fs-4 m-0'>مزايا العضويه</p>
                            <ul className='advantages px-3'>
                                <li className="mb-2">الحصول على جميع مزايا المذكورة في عضوية المنتسب للممارسين/الممارسات.</li>
                                <li className="mb-2">7 عضوية منتسب</li>
                                <li className="mb-2">وضع الشعار في جميع منصات وإعلانات المنصة</li>
                                <li className="mb-2">إمكانية الإعلان عن الشواغر لا محدودة</li>
                                <li className="mb-2">إمكانية استعراض سير ذاتية موصى عليها من قبل أعضاء المنصة</li>
                                <li className="mb-2">إمكانية مشاركة تقارير التجارب والمبادرات على المنصة</li>
                                <li className="mb-2">الرسوم 3000 ريال سعودي</li>
                            </ul>
                        </div>
                    </div>
                    <div className='card-aod col-4 px-2'>
                        <div className="basic"> 
                            <h5>العضويه الاساسيه</h5>
                            <p className='py-2 m-0'>1500 ريال</p>
                            <p className='p-0 m-0'>بلا حدود</p>
                            <Link to={"Memberships/6"} className='mt-2 btn btn-secondary text-white'>انضم الان</Link>
                            <hr className='h-r' />
                            <p className='py-2 fs-4 m-0'>مزايا العضويه</p>
                            <ul className='advantages px-3'>
                                <li className="mb-2">الحصول على جميع مزايا المذكورة في عضوية المنتسب للممارسين/الممارسات.</li>
                                <li className="mb-2">3 عضوية منتسب</li>
                                <li className="mb-2">وضع الشعار في جميع منصات وإعلانات المنصة</li>
                                <li className="mb-2">إمكانية الإعلان عن الشواغر لا محدودة</li>
                                <li className="mb-2">إمكانية استعراض سير ذاتية موصى عليها من قبل أعضاء المنصة</li>
                                <li className="mb-2">إمكانية مشاركة تقارير التجارب والمبادرات على المنصة</li>
                                <li className="mb-2">الرسوم 1500 ريال سعودي</li>
                            </ul>
                        </div>
                    </div>
                </Slider>
            </section>
            
    )
}
