import React, { useState } from 'react'

export default function Shrot() {
    const [open , setOpen] = useState(false);
    return (
        <>  
            {
                (open)&&<div className='shrot'>
                    <i className="bi bi-x closeing" onClick={()=>{
                        setOpen(false);
                    }}></i>
                    <h6 className='text-center mb-2'>الشروط والاحكام<i className="bi bi-cone me-2"></i></h6>
                    <p><i className="bi bi-check-all"></i>يحق لكل من الممارسين/الممارسات، والطلبة/الطالبات، والمنشآت الانضمام إلى عضوية المنصة وفقاً للشروط التالية</p>
                    <p><i className="bi bi-check-all"></i>أن يكون العضو سعودي الجنسية (تستثنى المنشآت من هذا البند).</p>
                    <p><i className="bi bi-check-all"></i>أن يكون العضو يعمل/يدرس في أحد مجالات الموارد الشرية أو المجالات ذات الصلة</p>
                    <p><i className="bi bi-check-all"></i>أن يلتزم العضو بحسن السيرة والسلوك في التعامل مع جميع أعضاء المجتمع وعدم القيام بأي أمر من شأنه إلحاق الضرر بكيان المنصة. </p>
                    <p><i className="bi bi-check-all"></i>حفظ حقوق مصدر المعلومات للمنصة وأعضاءها.</p>
                    <p><i className="bi bi-check-all"></i>تسديد الاشتراك السنوي.</p>
                    <p><i className="bi bi-check-all"></i>موافقة إدارة المنصة</p>
                    <p className='text-danger text-center'>تنتهي العضوية في حالة انسحاب العضو او مخالفته أي شرط من هذه الشروط</p>
                </div>
            }
            <div className='w-100 d-flex justify-content-center align-items-center mt-3'>
                <button className='btn btn-secondary' onClick={()=>{
                    setOpen(true);
                }}>الشروط والاحكام</button>
            </div>
        </>
    )
}
