import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const servicesProjectFirebaseConfig = {
    apiKey: "AIzaSyCt-43uhjYvsRL_26w7x8thAeuU9t2kVNY",
    authDomain: "ihrservices-6bd94.firebaseapp.com",
    projectId: "ihrservices-6bd94",
    storageBucket: "ihrservices-6bd94.appspot.com",
    messagingSenderId: "364436129701",
    appId: "1:364436129701:web:9d44f15fdd47cbea3eca4c",
    measurementId: "G-TRQ8TLLZDT"
};

// Initialize Firebase
const appservices = initializeApp(servicesProjectFirebaseConfig, "SERVICES");

export const authservices = getAuth(appservices);

export const dbservices = getFirestore(appservices);

export const storageservices = getStorage(appservices);