import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import MemberForm from './Forms/MemberForm'

export default function MembershipsSingls() {
    return (
        <section className='singls container mt-5'>
            <p className='fs-3'>عضويات الافراد</p>
            <div className='row w-100'>
                <div className='card-aod col-12 col-lg-4 px-2'>
                    <div>
                        <h5>عضويه منتسب</h5>
                        <p className='py-2 m-0'>150 ريال</p>
                        <p className='p-0 m-0'>لمده 12 شهر</p>
                        <Link to={"Memberships/0"} className='mt-2 btn btn-secondary text-white'>انضم الان</Link>
                        <hr className='h-r' />
                        <p className='py-2 fs-4 m-0'>مزايا العضويه</p>
                        <ul className='advantages px-3'>
                            <li className="mb-2">إدراج المنتسب ضمن دليل أعضاء المنصة</li>
                            <li className="mb-2">الاستفادة من كل ما تصدره المنصة من كتيبات ودوريات علمية واحصائيات ودراسات مهنية في مجال الموارد البشرية والثقافة المؤسسية</li>
                            <li className="mb-2">خصم خاص لدى شركاء المنصة والجهات التي تمنح خصومات خاصة على خدماتها للمسجلين في عضوية المنصة</li>
                            <li className="mb-2"> خاص لحضور كل فعاليات المنصة مثل اللقاءات السنوية والندوات والأمسيات والدورات التدريبية والرحلات في حال لم تكن مجانية</li>
                            <li className="mb-2">تطوير المهارات الفردية للعضو وتطوير أدائه الوظيفي واطلاعه على كل ما هو جديد ومفيد في مجال ‏الموارد البشرية</li>
                        </ul>
                    </div>
                </div>
                <div className='card-aod col-12 col-lg-4 px-2'>
                    <div>
                        <h5>عضويه عامل</h5>
                        <p className='py-2 m-0'>250 ريال</p>
                        <p className='p-0 m-0'>لمده 12 شهر</p>
                        <Link to={"Memberships/1"} className='mt-2 btn btn-secondary text-white'>انضم الان</Link>
                        <hr className='h-r' />
                        <p className='pb-2 fs-4 m-0'>مزايا العضويه</p>
                        <ul className='advantages px-3'>
                            <li className="mb-2">إدراج المنتسب ضمن دليل أعضاء المنصة</li>
                            <li className="mb-2">الاستفادة من كل ما تصدره المنصة من كتيبات ودوريات علمية واحصائيات ودراسات مهنية في مجال الموارد البشرية والثقافة المؤسسية</li>
                            <li className="mb-2">خصم خاص لدى شركاء المنصة والجهات التي تمنح خصومات خاصة على خدماتها للمسجلين في عضوية المنصة.</li>
                            <li className="mb-2">خصم خاص لدى شركاء المنصة والجهات التي تمنح خصومات خاصة على خدماتها للمسجلين في عضوية المنصة.</li>
                            <li className="mb-2">خصم خاص لدى شركاء المنصة والجهات التي تمنح خصومات خاصة على خدماتها للمسجلين في عضوية المنصة.</li>
                            <li className="mb-2">إمكانية الحصول على الاستشارات والطلبات الإدارية ذات العلاقة وفقاً لمجموع النقاط</li>
                            <li className="mb-2">إمكانية الحصول على الاستشارات والطلبات الإدارية ذات العلاقة وفقاً لمجموع النقاط</li>
                            <li className="mb-2">دخول المنصة الرقمية التفاعلية، حيث تضم جميع الاعضاء من زملاء المهنة من أصحاب الخبرات لتبادل المعرفة في مجال الموارد البشرية ونشر كل ما هو جديد عن المنصة</li>
                            <li className="mb-2">دخول المنصة الرقمية التفاعلية، حيث تضم جميع الاعضاء من زملاء المهنة من أصحاب الخبرات لتبادل المعرفة في مجال الموارد البشرية ونشر كل ما هو جديد عن المنصة</li>
                        </ul>
                    </div>
                </div>
                <div className='card-aod col-12 col-lg-4 px-2'>
                    <div>
                        <h5>عضويه طالب</h5>
                        <p className='py-2 m-0'>100 ريال</p>
                        <p className='p-0 m-0'>لمده 12 شهر</p>
                        <Link to={"Memberships/2"} className='mt-2 btn btn-secondary text-white' >انضم الان</Link>
                        <hr className='h-r'/>
                        <p className='pb-2 fs-4 m-0'>مزايا العضويه</p>
                        <ul className='advantages px-3'>
                            <li className="mb-2">الاستفادة من كل ما يصدره المجتمع من كتيبات ودوريات علمية واحصائيات ودراسات مهنية في مجال الموارد البشرية والثقافة المؤسسية</li>
                            <li className="mb-2">خصومات لدى شركاء المنصة والجهات التي تمنح تخفيضات خاصة على خدماتها للمسجلين في عضوية المنصة</li>
                            <li className="mb-2">خصم خاص لحضور كل فعاليات المنصة مثل اللقاءات السنوية والندوات والأمسيات والدورات التدريبية والرحلات في حال لم تكن مجانية</li>
                            <li className="mb-2">خصم خاص لحضور كل فعاليات المنصة مثل اللقاءات السنوية والندوات والأمسيات والدورات التدريبية والرحلات في حال لم تكن مجانية</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
