import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const eventsProjectFirebaseConfig = {
    apiKey: "AIzaSyA8BC4VCMLdF7P5DftekOiaZa64HIOvXH8",
    authDomain: "ihrevents-2fe69.firebaseapp.com",
    projectId: "ihrevents-2fe69",
    storageBucket: "ihrevents-2fe69.appspot.com",
    messagingSenderId: "484534457505",
    appId: "1:484534457505:web:e245e3929529600d118ce0",
    measurementId: "G-HSJQDH8DWN"
};

// Initialize Firebase
const appevents = initializeApp(eventsProjectFirebaseConfig, "EVENTS");

export const authevents = getAuth(appevents);

export const dbevents = getFirestore(appevents);

export const storageevents = getStorage(appevents);