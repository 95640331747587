import React, { useState } from 'react'
import {dbcontact} from '../firebase/firebasecontact';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import linkedimage from '../Assets/linkedin.jpeg';
import twiterimage from '../Assets/twitimage.jpg';
import youtube from '../Assets/youtubeimage.jpg';
import snapimages from '../Assets/snapimages.jpg';
export default function Contact() {
    const navigator = useNavigate();
    const [data,setData] = useState({});
    const [done,setDone] = useState(false);
    const submithandle = async(e)=>{
        e.preventDefault();
        try{
                await addDoc(collection(dbcontact,"contact"),{...data,timeStamp:serverTimestamp()});
                setData({});
                setDone(true);
                let handler = setTimeout(()=>{
                    navigator(0);
                    clearTimeout(handler);
                },1500);
        }catch(err){
            console.log(err);
        }
    }
    return (
        <section className='Contact p-1  mt-4 mx-0'>
            <div className='container p-0 d-flex justify-content-center'>
                <div className='row w-100'>
                    <div className='left col-12 col-lg-8 mb-2 mb-lg-0'>
                        <form dir='rtl' className='p-0 p-lg-5' onSubmit={(e)=>{
                            submithandle(e);
                        }}>
                            <p className='fs-6 ms-auto'>اتصل بنا</p>
                            <div className="w-100 mb-3 d-flex justify-content-between align-items-center" dir='rtl'>
                                <input required  dir='rtl' type="text" className="form-control" id="exampleFormControlInput1" placeholder="الاسم" name='user_name' onChange={(e)=>{
                                    setData({...data,name:e.target.value});
                                }}/>
                                <input required  dir='ltr' type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" name='user_email'onChange={(e)=>{
                                    setData({...data,email:e.target.value});
                                }}/>
                            </div>
                            <div className='mb-3 w-100'>
                                    <input required  dir='rtl' type={"tel"} className="form-control"  placeholder="رقم الجوال" name='user_phone' onChange={(e)=>{
                                        setData({...data,phone:e.target.value});
                                    }}/>
                            </div>
                            <div className="mb-3 w-100">
                                <textarea required dir='rtl' className="form-control" placeholder='رسالتك' id="exampleFormControlTextarea1" rows="3" name='user_massage' onChange={(e)=>{
                                    setData({...data,massage:e.target.value});
                                }}/>
                            </div>
                            <button type="submit" className="btn btn-cuton me-auto">ارسال</button>
                        </form>
                    </div>
                    <div className='right col-12 col-lg-4'>
                        <a href="tel:501511171"><p className='con my-2'>9660590158206+ <i className="bi bi-telephone-fill me-2 "></i></p></a>
                        <a className="mh-info-item mh-info-email-url" target={"_blank"} href="mailto:ihrsaudi@ihrsaudi.com"><p className="con my-2">ihrsaudi@ihrsaudi.com <i className="bi bi-envelope me-2"></i></p></a>
                        <a href="https://goo.gl/maps/k5SoiMWFQ9hcDS167" target={"_blank"} ><p className='con my-2'>القلم، المونسية، الرياض 11564<i className="bi bi-geo-alt-fill me-2"></i></p></a>
                        <div className='sociallinks justify-content-end justify-content-md-center'>
                            <a target={"_blank"} href='https://www.linkedin.com/in/ihr-saudi-community'>
                                <img src={linkedimage} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                            </a>
                            <a target={"_blank"} href='https://twitter.com/ihrsaudi'>
                                <img src={twiterimage} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                            </a>
                            <a target={"_blank"} href='https://www.youtube.com/channel/UCyaY-WfBPKGK2HUHND1GGRw/featured'>
                                <img src={youtube} alt="imagelinked" className='ms-3' style={{width:"30px",height:"25px",borderRadius:"5px"}}/>
                            </a>
                            <a target={"_blank"} href='https://www.snapchat.com/add/ihr.saudi'>
                                <img src={snapimages} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                            </a>
                        </div>
                        <iframe id="flex-grow" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.4005957480904!2d46.7569702!3d24.815970500000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee3d205021b77%3A0xb2526aa2892ba70e!2siHR%20Saudi%20Platform%20(iHRSP)!5e0!3m2!1sen!2ssa!4v1678728707487!5m2!1sen!2ssa" width={600} height={"auto"} style={{border:0}} allowFullScreen="" loading='lazy' referrerPolicy='' referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div className={`massage ${!done&&"active"}`}>
                <i className="bi bi-x-square-fill" onClick={()=>{setDone(false)}}></i>
                تم إرسال رسالتكم بنجاح ، وسيتم الرجوع إليكم قريباً ، شكراً لكم!
            </div>
        </section>
    )
}
