import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { dbevents, storageevents } from '../../firebase/firebaseevents';

export default function EventsHandle() {
    const navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../../signup');
    }
    const [eventss,setEventss] = useState(undefined);
    const [data,setData] = useState({});
    const [uploaded,setUploade] = useState(false);
    const handleImage = async(file)=>{
        const displayNAme = new Date().getTime() + file.name;
        const storageRef  = ref(storageevents, displayNAme);
        const uploadTask  = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(data);
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, 
            (error) => {
                console.log(error);
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setData({...data,img:`${downloadURL}`});
                    setUploade(true);
                });
            }
        );
    }
    const handleSubmit = async()=>{
        try{
            if(data.img!==undefined){
                await addDoc(collection(dbevents,"events"),{...data,timeStamp:serverTimestamp()});
                setData({});
                let handler = setTimeout(()=>{
                    navigator(0);
                    clearTimeout(handler);
                },1000);
            }
        }catch(err){
            console.log(err);
        }
    };
    useEffect(()=>{
        getData();
    },[]);
    const getData = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbevents, "events"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setEventss([...mutli]);
        }catch(e){
            console.log(e);
        }
    }
    const deletehandler = async(eid,imagLink)=>{
        try{
            await deleteDoc(doc(dbevents, "events", eid));
            const desertRef = ref(storageevents, `${imagLink}`);
            deleteObject(desertRef).then(() => {
                getData();
            }).catch((error) => {
            });
        }catch(e){
            console.log(e);
        }
    }
    return (
        <React.Fragment>
            <form className="form-bg m-3 py-3" onSubmit={(e)=>{
                e.preventDefault();
                handleSubmit();
            }}>
                <input className="form-control" type="file" onChange={(e)=>{
                    handleImage(e.target.files[0]);
                }}/>
                <input placeholder='العنوان' className="form-control my-2" required type="text" disabled={!uploaded&&true} onChange={(e)=>{
                    setData({...data,title:`${e.target.value}`});
                }}/>
                <button type="submit" disabled={!uploaded&&true} className="btn btn-primary">اضف فعاليه جديده</button>
            </form>
            <section className='eventsHandle row mt-4'>
                {(eventss!==undefined)&&(eventss.length!==0)?eventss.map((el)=>{
                    return(<div key={el.id} className="handlers">
                            <i className="bi bi-x-square-fill" onClick={()=>{
                                deletehandler(el.id,el.img);
                            }}></i>
                            <img src={el.img} alt="imag"></img>
                        </div>);
                }):<div>لا يوجد فعاليات الان</div>}
            </section>
        </React.Fragment>
    )
}
