import { signInWithEmailAndPassword } from 'firebase/auth';
import {authwats} from '../../firebase/firebasewts'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Signup() {
    const navigator = useNavigate();
    const [data,setData] = useState({});
    const [error,setError] = useState("");
    const [typeinput,setTypeInput] = useState("password");
    const singupData = async(e)=>{
        e.preventDefault();
        try{
            const res = await signInWithEmailAndPassword(authwats,data.email,data.password);
            const id = res.user.uid;
            sessionStorage.setItem('user',JSON.stringify({email:data.email,password:data.password,id:id}));
            if(id!==undefined){
                navigator("../dashboard");
            }
        }catch(err){
            setError(err.message);
        }
    }
    return (
        <section className='signupForm w-100 container'>
            <form onSubmit={(e)=>{
                singupData(e);
            }}>
                        <div className='d-flex justify-content-center align-items-start flex-column' >
                            <input placeholder='البريد الالكترونى' className="rounded p-1 my-2 w-100 px-1" required type={"email"} onChange={(e)=>{
                                setData({...data,email:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column' >
                            <i className="bi bi-eye-fill" onClick={(e)=>{
                                if(typeinput==="text"){
                                    e.target.className = "bi bi-eye-fill"; 
                                    setTypeInput("password");
                                }else{
                                    e.target.className = "bi bi-eye-slash-fill"; 
                                    setTypeInput("text");
                                }

                            }}></i>
                            <input placeholder='الرقم السرى' className="rounded w-100 p-1" required type={typeinput} onChange={(e)=>{
                                setData({...data,password:e.target.value});
                            }}/>
                        </div>
                        {(error!=="")&&<p className='p-0 my-2 text-danger'>{error}</p>}
                        <button type="submit" className="btn btn-primary my-2">الانضمام</button>
            </form>
        </section>
    )
}
