import { collection, deleteDoc,doc, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { dbcontact } from '../../firebase/firebasecontact';
import ReactLoading from 'react-loading';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useNavigate } from 'react-router-dom';
export default function Contact() {
    let navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../../signup');
    }
    const [contacts,setContacts] = useState(undefined);
    const tableRef = useRef(null);
    const [reload,setReload] = useState(true);
    const deletehandler = async(eid)=>{
        try{
            await deleteDoc(doc(dbcontact, "contact", eid));
            getData();
        }catch(e){
            console.log(e);
        }
    }
    useEffect(()=>{
        getData();
    },[])
    const getData = async()=>{
        try{
            let mutli = [];
            const next = query(collection(dbcontact, "contact"),
                orderBy("timeStamp"));
            const querySnapshot = await getDocs(next);
            let counter = 1;
            querySnapshot.forEach((doc) => {
                mutli.push(<tr key={doc.id}>    
                    <td><i className="bi bi-x-square-fill text-nowrap deleting" onClick={(e)=>{
                            deletehandler(doc.id,(doc.data()).cvfile);
                    }}></i></td>
                    <td>{counter}</td>
                    <td>{(doc.data()).name}</td>
                    <td>{(doc.data()).email}</td>
                    <td>{(doc.data()).phone}</td>
                    <td>{(doc.data()).massage}</td>
                </tr>);
                counter++;
            });
            setContacts([...mutli])
        }catch(e){
            console.log(e);
        }
    }  
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'contact_table',
        sheet: 'contact'
    })
return (
    
    <React.Fragment>{(contacts!==undefined)?(contacts.length!==0)?
        <React.Fragment>
        <button className='btn btn-primary ms-3' disabled={(contacts!==undefined&&contacts.length===0)?true:false} onClick={()=>{
            setReload(!reload);
        }}>اريد تحميل الجدول</button>
        <button  className='btn btn-primary ms-auto my-2' onClick={onDownload}>فتح فى اكسيل شيت</button>
        <div className="table-responsive mt-3">
                <table className="table table-secondary" ref={tableRef}>
                    <thead >
                        <tr>
                        <th className='text-nowrap'>امسح</th>
                        <th className='text-nowrap'>#رقم</th>
                        <th className='text-nowrap'><i className="bi bi-person-fill"></i> الاسم</th>
                        <th className='text-nowrap'><i className="bi bi-flag-fill"></i> الايميل</th>
                        <th className='text-nowrap'><i className="bi bi-person-fill"></i> رقم الهاتف</th>
                        <th className='text-nowrap'><i className="bi bi-globe-asia-australia"></i> الرساله</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts}
                    </tbody>
                </table>
        </div>
        </React.Fragment>:<div>لا يوجد رسائل الان</div>:
        <div className='w-100 mt-5 d-flex justify-content-center align-items-center'><ReactLoading type={"spinningBubbles"} color={"#1884c2"} height={150} width={150} /></div>
    }
    </React.Fragment>
)
}

