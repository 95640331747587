import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import img1 from '../../Assets/partners/3dots.png'
import img2 from '../../Assets/partners/aihr.png'
import img3 from '../../Assets/partners/Picture3.png'
import img4 from '../../Assets/partners/hrdf.png'
import img5 from '../../Assets/partners/innovers.jpeg'
import img6 from '../../Assets/partners/Picture6.png'
import img7 from '../../Assets/partners/kippy.png'
import img8 from '../../Assets/partners/kpi mega library.png'
import img9 from '../../Assets/partners/Picture9.svg'
import img10 from '../../Assets/partners/group.jpg'
import img11 from '../../Assets/partners/dakil.png'
import img12 from '../../Assets/partners/Picture12.jpg'
import img13 from '../../Assets/partners/Picture13.jpg'
import img14 from '../../Assets/partners/number.png'
import img15 from '../../Assets/partners/Picture15.png'
import img16 from '../../Assets/partners/rules.png'
import img17 from '../../Assets/partners/king.png'
import img18 from '../../Assets/partners/yamama.jpg'
import img19 from '../../Assets/partners/Picture19.png'
import img20 from '../../Assets/partners/home.png'
import img21 from '../../Assets/partners/Picture21.jpg'
import img22 from '../../Assets/partners/outer.png'
import img23 from '../../Assets/partners/harverd.png'
export default function Parteners() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className='mt-4 mt-lg-5 parteners container'>
            <h2 className="main-title mb-lg-4 mb-1 ">شركائنا</h2>
            <Slider {...settings} className='w-100'>
                {[img23,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img1].map((item,index)=>{
                    return(<div key={index} className="w-100 custom-card px-2 part-img">
                            <img className="imag-phone" src={item} alt='iageCa' />    
                    </div>)
                })}
            </Slider>
        </section>
    )
}
