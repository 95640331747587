import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Addcv from './cv/Addcv';
import Showcv from './cv/Showcv';
export default function CV() {
  const [active,setActive] = useState(0);
    return (
        <div className='container Cv my-3'>
            <ul className="nav nav-pills">
                <li className={`nav-item ${(active===0&&"active-custom")}`}>
                    <button className="nav-link" aria-current="page" onClick={()=>{
                      setActive(0)
                    }}>اضافه سيرة الذاتية</button>
                </li>
                <li className={`nav-item ${(active===1&&"active-custom")}`}>
                    <button className="nav-link" onClick={()=>{
                      setActive(1);
                    }} aria-current="page" >عرض السير الذاتية</button>
                </li>
            </ul>
            {
              (active===0)?<Addcv />:<Showcv />
            }
        </div>
    )
}

