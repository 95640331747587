import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const watsProjectFirebaseConfig = {
    apiKey: "AIzaSyAhK0TXIhCd9oVPEp_E5GmOTHmo05OIgww",
    authDomain: "ihrwats.firebaseapp.com",
    projectId: "ihrwats",
    storageBucket: "ihrwats.appspot.com",
    messagingSenderId: "1019888270097",
    appId: "1:1019888270097:web:176c46ecf0b6ad54a2eab0",
    measurementId: "G-FG41DTDVBZ"
};

// Initialize Firebase
const appwats = initializeApp(watsProjectFirebaseConfig, "WATSAPP");

export const authwats = getAuth(appwats);

export const dbwats = getFirestore(appwats);

export const storagewats = getStorage(appwats);