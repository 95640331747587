import React, { useState } from "react";
import applic from '../Assets/Picture1.jpg';
import { Link } from "react-router-dom";
import logo2 from '../Assets/ihrsp@2x.png';
import watsicon from '../Assets/WatsIcon.png';
import tarif from '../Assets/iHR Saudi Platform Corprate Profile.pdf'
import linkedimage from '../Assets/linkedin.jpeg';
import twiterimage from '../Assets/twitimage.jpg';
import youtube from '../Assets/youtubeimage.jpg';
import snapimages from '../Assets/snapimages.jpg';
export default function Navbar() {
    const [open,setOpen] = useState(false);
return (
    <>
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container p-0 justify-content-xl-start">
                    <button className="btn btn-secondary ms-lg-4 me-4 me-lg-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <i className="bi bi-list"></i>
                    </button>
                    <a id="campony" className="navbar-brand p-0 m-0" href="#">
                        <img style={{height:'100px',width:'auto'}} src={logo2} alt="logo"/>
                    </a>
                    <div className="collapse d-xl-block d-sm-none" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item ms-2">
                                <Link to={''} className="nav-link active" aria-current="page" href="#">
                                <i className="bi bi-house-door ms-1"></i>
                                الرئيسية 
                                </Link>
                            </li>
                            <li className="nav-item ms-2">
                                <Link to={'AdministrativeTeam'} className="nav-link" href="#">
                                <i className="bi bi-people ms-1"></i>الفريق الإداري 
                                </Link>
                            </li>
                            <li className="nav-item ms-2">
                                <Link to={"AdvisoryBoard"} className="nav-link" href="#">
                                <i className="bi bi-people-fill ms-1"></i>المجلس الاستشاري 
                                </Link>
                            </li>
                            <li className="nav-item dropdown ms-2">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-person-vcard-fill ms-1"></i>العضويات 
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link to={'MembershipsSingls'} className="dropdown-item text-end" href="#"><i className="bi bi-person ms-1"></i>عضويه الافراد </Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link to={'Membershipscompanys'} className="dropdown-item text-end" href="#"><i className="bi bi-people ms-1"></i>عضويه مؤسسية</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item ms-2">
                                <Link to={'Aboutus'} className="nav-link" href="#">
                                <i className="bi bi-buildings-fill ms-1"></i>من نحن 
                                </Link>
                            </li>
                            <li className="nav-item ms-2">
                                <a download={"iHR Saudi Platform Corprate Profile.pdf"} className="nav-link" href={tarif}>
                                    <i className="bi bi-filetype-pdf ms-1"></i>الملف التعريفى
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="offcanvas offcanvas-end" tabIndex={"-1"} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">روابط اضافية</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <img style={{height:'150px',width:'100%',display:'block'}} src={logo2} alt="logo"/>
                    <ul className="side-links">
                    <li className="nav-item mb-2 d-xl-none ">
                        <Link to={''} className="nav-link active" aria-current="page" href="#">
                        <i className="bi bi-house-door ms-1"></i>الرئيسية 
                        </Link>
                    </li>
                    <li className="nav-item mb-2 d-xl-none">
                        <Link to={'Aboutus'} className="nav-link" href="#">
                        <i className="bi bi-buildings-fill ms-1"></i> من نحن 
                        </Link>
                    </li>
                    <li className="nav-item ms-2 d-xl-none">
                        <a download={"iHR Saudi Platform Corprate Profile.pdf"} className="nav-link" href={tarif}>
                        <i className="bi bi-filetype-pdf ms-1"></i>الملف التعريفى
                        </a>
                    </li>
                    <li className="nav-item mb-2 d-xl-none">
                        <Link to={'AdministrativeTeam'} className="nav-link" href="#">
                        <i className="bi bi-people ms-1"></i>الفريق الإداري 
                        </Link>
                    </li>
                    <li className="nav-item mb-2 d-xl-none">
                        <Link to={"AdvisoryBoard"} className="nav-link" href="#">
                        <i className="bi bi-people-fill ms-1"></i>المجلس الاستشاري 
                        </Link>
                    </li>
                    <li className="nav-item dropdown mb-2 d-xl-none">
                        <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-person-vcard-fill ms-1"></i>العضويات 
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link to={'MembershipsSingls'} className="dropdown-item text-end" href="#"><i className="bi bi-person ms-1"></i>عضويه الافراد</Link></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><Link to={'Membershipscompanys'} className="dropdown-item text-end" href="#"><i className="bi bi-people ms-1"></i>عضويه مؤسسية</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={'Services'} className="nav-link" href="#">
                        <i className="bi bi-database-add ms-1"></i>خدماتنا  
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={"CV"} className="nav-link" href="#">
                        <i className="bi bi-file-earmark-person ms-1"></i>بنك السير الذاتية     
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={'Watsapp'} className="nav-link" href="#">
                            <i className="bi bi-whatsapp ms-1"></i>
                            مجموعات الواتساب 
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={"Reports"} className="nav-link" href="#">
                            <i className="bi bi-clipboard2-data ms-1"></i>   
                            التقارير السنوية      
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <a className="nav-link" href="#" onClick={(e)=>{
                            e.preventDefault();
                            setOpen(true);
                        }}>
                            <i className="bi bi-phone-fill ms-1"></i>   
                            تطبيق iHR
                        </a>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={"Says"} className="nav-link" href="#">
                            <i className="bi bi-person-check-fill ms-1"></i>   
                            قالوا عنا  
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={"Contact"} className="nav-link" href="#">
                            <i className="bi bi-telephone-fill ms-1"></i>  
                            اتصل بنا 
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link to={"Privacy"} className="nav-link" href="#">
                        <i className="bi bi-bookmark-check ms-1"></i>سياسة الخصوصية   
                        </Link>
                    </li>
                    </ul>
                </div>
                <div className="offcanvas-footer mb-2">
                        <div className='sociallinks justify-content-end justify-content-md-center'>
                            <a target={"_blank"} href='https://www.linkedin.com/in/ihr-saudi-community'>
                                <img src={linkedimage} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                            </a>
                            <a target={"_blank"} href='https://twitter.com/ihrsaudi'>
                                <img src={twiterimage} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                            </a>
                            <a target={"_blank"} href='https://www.youtube.com/channel/UCyaY-WfBPKGK2HUHND1GGRw/featured'>
                                <img src={youtube} alt="imagelinked" className='ms-3' style={{width:"30px",height:"25px",borderRadius:"5px"}}/>
                            </a>
                            <a target={"_blank"} href='https://www.snapchat.com/add/ihr.saudi'>
                                <img src={snapimages} alt="imagelinked" className='ms-3' style={{width:"25px",height:"25px"}}/>
                            </a>
                        </div>
                </div>
            </div>
            {open&&<div className="Application">
                <i className="bi bi-x-square-fill clossss" onClick={(e)=>{
                    setOpen(false);
                }}></i>
                <img src={applic} alt="app"/>
            </div>}
        </header>
        {
            // <a href="https://wa.me/+966590158206" id="watsLink" ><i className="bi bi-whatsapp"></i></a>
        }
        <a href="https://wa.me/+966590158206" id="watsLink" ><img id="watsIconstyle" src={watsicon} alt="wats"/></a>
        
    </>
);
}
