import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { dbnews, storagenews } from '../../firebase/firebasenews';

export default function Nweshandler() {
    const navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../../signup');
    }
    const [nwes,setNwes] = useState([]);
    const [data,setData] = useState({});
    const [upload,setUploade] = useState(true);
    useEffect(()=>{
        getData();
    },[]);
    const getData = async()=>{
        try{
            let mutli = [];
            const querySnapshot = await getDocs(collection(dbnews, "nwes"));
            querySnapshot.forEach((doc) => {
                mutli.push({...(doc.data()),id:doc.id})
            });
            setNwes([...mutli]);
        }catch(e){
            console.log(e);
        }
    }
    const handleImage = async(file)=>{
        const displayNAme = new Date().getTime() + file.name;
        const storageRef  = ref(storagenews, displayNAme);
        const uploadTask  = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        break;
                }
            }, 
            (error) => {
                console.log(error);
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setData({...data,img:`${downloadURL}`});
                    setUploade(true);
                });
            }
        );
    }
    const submitHandle = async()=>{
        try{
            if(data.img!==undefined){
                await addDoc(collection(dbnews,"nwes"),{...data});
                navigator(0);
            }
        }catch(err){
            console.log(err);
        }
    };
    const deletehandler = async(eid,imagLink)=>{
        try{
            await deleteDoc(doc(dbnews, "nwes", eid));
            const desertRef = ref(storagenews, `${imagLink}`);
            deleteObject(desertRef).then(() => {
                getData();
            }).catch((error) => {
            });
        }catch(e){
            console.log(e);
        }
    }
    return (
        <>
            <form onSubmit={(e)=>{
                e.preventDefault();
                submitHandle();
            }} className="form-bg m-3 py-3">
                <input required type={"file"}  className="form-control" onChange={(e)=>{
                    setUploade(false);
                    handleImage(e.target.files[0]);
                }}/>
                <input required type={"text"} className="my-3 form-control" placeholder="العنوان" disabled={!upload&&true} onChange={(e)=>{
                    setData({...data,title:`${e.target.value}`});
                }}/>
                <textarea required type={"text"} className="form-control" placeholder="المقاله" disabled={!upload&&true} onChange={(e)=>{
                    setData({...data,body:`${e.target.value}`});
                }}/>
                <button type='submit' className={`btn btn-primary mt-2 w-auto`} disabled={!upload&&true}>اضف خير جديد</button>
            </form>
            <section className='eventsHandle row mt-4'>
                {(nwes.length!==0)&&nwes.map((el)=>{
                    const style = {
                        objectFit:"contain",
                        objectPosition: "center",
                        width: "100%",
                        // height: "600px",
                    }
                    return(
                        <div key={el.id} className="col-4 custom-card px-2 handlers">
                            <i className="bi bi-x-square-fill" onClick={()=>{
                                deletehandler(el.id,el.img);
                            }}></i>
                            <img style={{...style}} src={el.img} alt='iageCa' />
                            <p className='my-2 fs-6 custom-p text-right'>{el.title}</p>
                            <p className='text-right'>{el.body}</p>
                        </div>
                    );
                })}
            </section>
        </>
    )
}
