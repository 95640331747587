import React from 'react'
import img1 from '../Assets/reports/images/Imgae2018.jpeg'
import img2 from '../Assets/reports/images/Imgae2019.jpeg'
import img3 from '../Assets/reports/images/Imgae2020.jpeg'
import pdf1 from '../Assets/reports/pdfs/iHR Saudi Platform 2018.pdf'
import pdf2 from '../Assets/reports/pdfs/iHR Saudi Platform 2019.pdf'
import pdf3 from '../Assets/reports/pdfs/iHR Saudi Platform 2020.pdf'
export default function Reports() {
    return (
        <section className='Reports p-1  mt-4 mx-0'>
                <div className='container p-0 d-flex justify-content-center flex-column'>
                    <h2 className="main-title mb-4 p-2">التقارير السنويه</h2>
                    <div className='row w-100 d-flex justify-content-center mx-0'>
                            <div className='rep col-12 col-md-6 col-lg-3 mb-2 mb-lg-0'>
                                <div className='covering-image'>
                                    <img src={img1} alt='pdf'/>
                                    <div className='contorl px-2'>
                                        <a className="primary text-white" href={pdf1} download="iHR Saudi Platform 2018.pdf">
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>تحميل
                                        </a>
                                        <a className="primary" href={pdf1} target="_blank" >
                                            <i className="bi bi-eye-fill"></i>اطلاع
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='rep col-12 col-md-6 col-lg-3 mb-2 mb-lg-0'>
                                <div className='covering-image'>
                                    <div className='contorl px-2'>
                                            <a className="primary" href={pdf2} download="iHR Saudi Platform 2019.pdf">
                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>تحميل
                                            </a>
                                            <a className="primary" href={pdf2} target="_blank">
                                                <i className="bi bi-eye-fill"></i>اطلاع
                                            </a>
                                    </div>
                                    <img src={img2} alt='pdf'/>
                                </div>
                            </div>
                            <div className='rep col-12 col-md-6 col-lg-3 mb-2 mb-lg-0'>
                                <div className='covering-image'>
                                    <img src={img3} alt='pdf'/>
                                    <div className='contorl px-2'>
                                        <a className="primary" href={pdf3} download="iHR Saudi Platform 2020.pdf">
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>تحميل
                                        </a>
                                        <a className="primary" href={pdf3} target="_blank">
                                            <i className="bi bi-eye-fill"></i>اطلاع
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
        </section>
    )
}
