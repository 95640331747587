import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const cvsProjectFirebaseConfig = {
    apiKey: "AIzaSyC1QBbAyeqYMd9Yd6iatWL8aD4wnYMPR_Q",
    authDomain: "ihrcv-8c7d6.firebaseapp.com",
    projectId: "ihrcv-8c7d6",
    storageBucket: "ihrcv-8c7d6.appspot.com",
    messagingSenderId: "253140686500",
    appId: "1:253140686500:web:f1298e26e91aee30d57831",
    measurementId: "G-GGTTHY5RPT"
};

// Initialize Firebase
const appcvs = initializeApp(cvsProjectFirebaseConfig , "CVS");

export const authcvs = getAuth(appcvs);

export const dbcvs = getFirestore(appcvs);

export const storagecvs = getStorage(appcvs);