import React, { useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import pic1 from '../Assets/aims/Picture22.svg'
import pic2 from '../Assets/aims/Picture23.svg'
import pic3 from '../Assets/aims/Picture24.svg'
import pic4 from '../Assets/aims/Picture25.svg'
import pic5 from '../Assets/aims/Picture26.svg'
import pic6 from '../Assets/aims/Picture27.svg'
import pic7 from '../Assets/aims/Picture28.svg'
import pic8 from '../Assets/aims/Picture29.svg'
import img1 from '../Assets/futureImages/Picture30.svg';
import img2 from '../Assets/futureImages/Picture31.svg';
import img3 from '../Assets/futureImages/Picture32.svg';
import img4 from '../Assets/futureImages/Picture33.svg';
import img5 from '../Assets/futureImages/Picture34.png';
import img6 from '../Assets/futureImages/Picture35.svg';
import {futsures} from '../Assets/futsures';
export default function Aboutus() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };
    const arr = [img1,img2,img3,img4,img5,img6];
    const [current,setCureent] = useState(1);
    const [active,setActive] = useState(1);
    return (
        <section className='Aboutus container mt-5'>
            <h2 className="main-title mb-4 ">من نحن</h2>
            <div className='abou'>
                <article className='text-right mb-2'>مجتمع احترافي يتضمن نخبة من الكوادر السعودية المتميزة والمتخصصة في مجال الموارد البشرية من مختلف قطاعات الأعمال (الحكومي/الخاص) والمدن، تحت قيادة فريق سعودي متخصص
                </article>
                <article className='text-right mb-2'>حيث تتواكب هذه المبادرة مع رؤية 2030م ومستهدفات برنامج التحوّل الوطني 2020م والذي يعد من ضمن أهدافه واستراتيجياته رفع المستوى المهاري للسعوديين وذلك بتزويدهم بالمعارف والمهارات اللازمة لموائمة احتياجات سوق العمل بالإضافة إلى توفير فرص عمل لائقة بهم وفقاً إلى ذلك. 
                يسعى المجتمع لإحداث نقلة فريدة ونوعية للعاملين في هذا المجال، وذلك بالعملِ الجاد على تعزيز مفهوم الموارد البشرية الحديث علمياً وعملياً، مما يساهم بالإرتقاء بالمعارف والمهارات والممارسات لدى ممارسي الموارد البشرية لتحقيق نتائج ملموسة، بالإضافة إلى المهتمين في هذا المجال من غير المختصين والطلاب وحديثي التخرج، ليستفيد منها كلاً من المنشآت والأفراد العاملين والباحثين عن العمل.
                </article>
            </div>
            <hr className=''/>
            <h2 className="main-title mb-4 ">الفئات المستهدفة</h2>
            <div className='row'>
                <div className='col-12 col-lg-6 col-xl-4 mb-3 p-0'>
                    <div className="card mx-auto p-2" style={{width:"18rem"}}>
                        <img src={pic1} className="card-img-top imgaim" alt="..." />
                        <div className="card-body mb-0 pb-0">
                            <p className="card-text">ممارسي الموارد البشرية (المبتدئ، المتوسط، المتقدم).</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-4 mb-3 p-0' >
                    <div className="card mx-auto p-2" style={{width:"18rem"}}>
                        <img src={pic2} className="card-img-top imgaim" alt="..." />
                        <div className="card-body mb-0 pb-0">
                            <p className="card-text">الباحثين والباحثات عن العمل من أصحاب الخبرة والكفاءات.</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-4  mb-3 p-0'>
                    <div className="card mx-auto p-2" style={{width:"18rem"}}>
                        <img src={pic3} className="card-img-top imgaim" alt="..." />
                        <div className="card-body mb-0 pb-0">
                            <p className="card-text">حديثي التخرج من الجامعات والمؤسسات التعليمية.</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-4 mb-3 p-0'>
                    <div className="card mx-auto p-2" style={{width:"18rem"}}>
                        <img src={pic4} className="card-img-top imgaim" alt="..." />
                        <div className="card-body mb-0 pb-0">
                            <p className="card-text">المهتمين في مجال الموارد البشرية من غير المختصين.</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-4 mb-3 p-0'>
                    <div className="card mx-auto p-2" style={{width:"18rem"}}>
                        <img src={pic5} className="card-img-top imgaim" alt="..." />
                        <div className="card-body mb-0 pb-0">
                            <p className="card-text">طلبة الجامعات والمؤسسات التعليمية المتوقع دخولهم سوق العمل.</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className=''/>
            <h2 className="main-title mb-4 ">نطاق الاستهداف</h2>
            <Slider {...settings}>
                    <div className="w-100 custom-card">
                        <div className="card p-2 om">
                            <div className="card-body mb-0 pb-0">
                                <h5 className="card-title text-right text-cu">الموظفون</h5>
                                <p className="card-text text-right">إننا نبني قيمة للموظفين بمنحهم فرص للارتقاء والتقدم وبيئة عمل مفعمة بالتعليم والتطوير والتحديات والنمو.</p>
                            </div>
                            <img src={pic6} className="card-img-top imgaim ms-1 p-2" alt="..." />
                        </div>
                    </div>
                    <div className="w-100 custom-card">
                        <div className="card p-2 om">
                            <div className="card-body mb-0 pb-0">
                                <h5 className="card-title text-right text-cu">الشركاء</h5>
                                <p className="card-text text-right">إننا نبني قيمة متبادلة مع شركائنا في هذا المجال.</p>
                            </div>
                            <img src={pic7} className="card-img-top imgaim ms-1 p-2" alt="..." />
                        </div>
                    </div>
                    <div className="w-100 custom-card">
                        <div className="card p-2 om">
                            <div className="card-body mb-0 pb-0">
                                <h5 className="card-title text-right text-cu">المجتمع</h5>
                                <p className="card-text text-right">إننا نبني قيمة لمجتمعاتنا مرتكزة على الخدمات المستدامة في مجال المسؤولية الاجتماعية، وأساسها الاحتياجات المجتمعة وظروفها.</p>
                            </div>
                            <img src={pic8} className="card-img-top imgaim ms-1 p-2" alt="..." />
                        </div>
                    </div>
            </Slider>
            <hr className=''/>
            <h2 className="main-title mb-4 "><i className="bi bi-bullseye ms-1 fs-5"></i>هدفنا</h2>
            <div className='row'>
                <article className='text-right mb-2'><i className="bi bi-bullseye fs-3 ms-2 goalicon"></i>نسعى لإحداث نقلة فريدة ونوعية للعاملين في هذا المجال، وذلك بالعمل الجاد على تعزيز مفهوم الموارد البشرية الحديث علمية وعملية ، مما يساهم بالإرتقاء بالمعارف والمهارات والممارسات لدى مختصي الموارد البشرية لتحقيق نتائج ملموسة ، تستفيد منها المؤسسات و الأفراد العاملين والباحثين في هذا المجال
                </article>
            </div>
            <hr />
            <h2 className="main-title mb-4 "><i className="bi bi-arrow-up ms-1 fs-5"></i>مستقبلنا</h2>
            <div className='row w-100'>
                <div className='step-progress mb-3'>
                        <span className={(active===1?"active":"")} onClick={()=>{
                            setCureent(1);
                            setActive(1);
                        }}>1</span>
                        <span className={(active===2?"active":"")} onClick={()=>{
                            setCureent(2);
                            setActive(2);
                        }}>2</span>
                        <span className={(active===3?"active":"")} onClick={()=>{
                            setCureent(3);
                            setActive(3);
                        }}>3</span>
                        <span className={(active===4?"active":"")} onClick={()=>{
                            setCureent(4);
                            setActive(4);
                        }}>4</span>
                        <span className={(active===5?"active":"")} onClick={()=>{
                            setCureent(5);
                            setActive(5);
                        }}>5</span>
                        <span className={(active===6?"active":"")} onClick={()=>{
                            setCureent(6);
                            setActive(6);
                        }}>6</span>
                </div>
                {
                    <div className="w-100 custom-card px-2">
                        <div className="card p-2 om">
                            <div className="card-body mb-0 pb-0 om">
                                <p className="card-text text-right">{futsures[current-1].text}</p>
                            </div>
                            <img src={arr[current-1]} className="card-img-top imgaim ms-lg-1 ms-auto p-2" alt="..." />
                        </div>
                </div>}
            </div>
        </section>
    )
}
