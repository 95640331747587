import React from 'react'
import { Link } from 'react-router-dom'
export default function Watsapp() {
    // WatsappForm
    return (
        <section className='watsapp container mt-4'>
            <div className='row'>
                <div className='col-12 col-lg-4 px-lg-2 mb-2 mb-lg-0'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-people-fill ps-1 ms-2"></i>
                            مجموعة العمليات
                        </div>
                        <div className="card-body">
                            <p>تعنى هذه المجموعة بكل ما يتعلق بإدارة موظفي المنشأة اليومية وتنفيذ العمليات
                            الخاصة بالموارد البشرية بما ينسجم مع الأنظمة والقوانين ذات العلاقة في
                            المملكة العربية السعودية ، على سبيل المثال : التوظيف ، التعويضات ، الإجازات ،
                            الحضور والإنصراف ، العمل الإضافي ، التأمينات الصحية ، التأمينات الإجتماعية ،
                            السلف المالية ، الانتدابات ، الرواتب ، الحسومات ،التظلمات والشكاوي.</p>
                        </div>
                        <div className='card-footer'>
                            <Link to={"Watsapp/0"} id='linkw'><p className='fs-lg-5 fs-6 m-0 p-1 p-lg-2 mb-2 text-center cover'>انضم لمجموعات الواتساب الان <i className="bi bi-whatsapp"></i></p></Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 px-lg-2 mb-2 mb-lg-0'>
                    <div className="card">
                        <div className="card-header">
                            <i className="bi bi-people-fill ps-1 ms-2"></i>
                            مجموعة التخطيط
                        </div>
                        <div className="card-body">
                            <p>تعنى هذه المجموعة بالنواحي الاستراتيجية للموارد البشرية ومنها على سبيل المثال :
                            الهيكل التنظيمي ، التطوير والتنمية ، التدريب ، تحليل القوى العاملة ، تحليل
                            أوضاع الموظفين ، الوصف الوظيفي ، التحليل الوظيفي ، النقل والتعديل ، المزايا
                            والمكافئات، إدارة الأداء ، سلم الرواتب والأجور، حيث ستشمل المجموعة أنشطة
                            جديدة مع استمرار برنامج النقاش الاسبوعي القائم حالياً.</p>
                        </div>
                        <div className='card-footer'>
                            <Link to={"Watsapp/1"} id='linkw'><p className='fs-lg-5 fs-6 m-0 p-1 p-lg-2 mb-2 text-center cover'>انضم لمجموعات الواتساب الان <i className="bi bi-whatsapp"></i></p></Link>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 px-lg-2 mb-2 mb-lg-0'>
                    <div className="card h-100">
                        <div className="card-header">
                            <i className="bi bi-people-fill ps-1 ms-2"></i>
                            مجموعة نشر الوظائف
                        </div>
                        <div className="card-body flex-grow-1 justify-content-between flex-column align-items-center d-flex">
                            <p>تختص هذه المجموعة باعلانات الوظائف الشاغرة ، شريطة أن تكون هذه الشواغر إما في نفس منظمة العضو المعلن، أو أن تكون من
                            منظمات أخرى على أن تكون موثوقة متضمنة عناوين بريد إلكتروني غير شخصية أو مبهمة .</p>
                        </div>
                        <div className='card-footer'>
                            <Link to={"Watsapp/2"} id='linkw'><p className='fs-lg-5 fs-6 m-0 p-1 p-lg-2 mb-2 text-center cover'>انضم لمجموعات الواتساب الان <i className="bi bi-whatsapp"></i></p></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card text-center mt-lg-4 flex-column flex-lg-row">
                <div className="card-header">
                    <i className="bi bi-people-fill ps-1 ms-2"></i>
                    مجموعة نشر السير الذاتية
                </div>
                <div className="card-body">
                    <p>تختص هذه المجموعة بمشاركة السير الذاتية للسعوديين للباحثين
                    عن فرص وظيفية من ذوي الكفاءات في سوق العمل .</p>
                </div>
                <div className='card-footer'>
                            <Link to={"Watsapp/3"} id='linkw'><p className='fs-lg-5 fs-6 m-0 p-1 p-lg-2 mb-2 text-center cover'>انضم لمجموعات الواتساب الان <i className="bi bi-whatsapp"></i></p></Link>
                        </div>
                </div>
        </section>
    )
}