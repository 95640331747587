import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { dbwats } from '../../firebase/firebasewts';
import "react-step-progress-bar/styles.css";
import logo from '../../Assets/ihrsp@2x.png';
export default function WatsappForm() {
    const {id} = useParams();
    const param = Number(id);
    const arr = [
        {
            type:'مجموعة العمليات',
        },
        {
            type:'مجموعة التخطيط',
        },
        {
            type:'مجموعة نشر الوظائف',
        },
        {
            type:'مجموعة نشر السير الذاتية',
        },
    ]
    const type = arr[param].type;
    const [data,setData] = useState({
        national:"سعودى",
        currentBusiness:"لا يوجد",  
        gender:"ذكر",
        type:type,
    });
    const navigator = useNavigate();
    const [done,setDone] = useState(false);
    const handleSubmit = async()=>{
        try{
                await addDoc(collection(dbwats,"watsapp"),{...data,timeStamp:serverTimestamp()});
                setData({});
                setDone(true);
                let handler = setTimeout(()=>{
                    navigator(0);
                    clearTimeout(handler);
                },1000);
        }catch(err){
            console.log(err);
        }
    };
    return (
        <section className='container mt-4'>
            <div className='formmember'>
                <img style={{height:'100px',width:'auto'}} src={logo} alt="logo"/>
                <h5 className='title text-right my-2'>كيف تتنضم الينا؟</h5>
                <p className='title text-right my-2'>مراجعه البيانات :</p>
                <p className='text-right'><i className="bi bi-1-circle-fill ms-2 text-right"></i>اسم المجموعه  :  {type} </p>
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    handleSubmit();
                }}>
                    <p className='custom-header p-1 title my-4'>المعلومات الشخضيه</p>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='d-flex justify-content-center align-items-start flex-column'>
                            <label>الاسم<span>*</span></label>
                            <input   className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                                setData({...data,name:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>النوع<span>*</span></label>
                            <select className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                                setData({...data,gender:e.target.value});
                            }}>
                                <option value="ذكر">ذكر</option>
                                <option value="أنثى">أنثى</option>
                            </select>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='d-flex justify-content-center align-items-start flex-column'>
                            <label>رقم الجوال<span>*</span></label>
                            <input  className="rounded p-1 mt-2 w-100 px-1" required type={"tel"} inputMode={"tel"}  data-key="mobile_number" onChange={(e)=>{
                                setData({...data,phone:e.target.value});
                            }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>المدينة<span>*</span></label>
                            <input  className="rounded p-1 mt-2 w-100" type={'text'} id="city" name="city" autoComplete='address-level2' required="" enterKeyHint='next' onChange={(e)=>{
                                setData({...data,city:e.target.value});
                            }}/>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className=' d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>الجنسيه<span>*</span></label>
                            <select className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                                setData({...data,national:e.target.value});
                            }}>
                                <option value="سعودى">سعودى</option>
                                <option value="غير سعودى">غير سعودى</option>
                            </select>
                        </div>
                    </div>
                    <p className='custom-header p-1 title my-4 '>المعلومات الوظيفة</p>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className=' d-flex justify-content-center align-items-start flex-column'>
                                <label>المسمى الوظيفي (بالعربي)<span>*</span></label>
                                <input  className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                                    setData({...data,JobTitle:e.target.value});
                                }}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-start flex-column px-1'>
                            <label>قطاع العمل الحالي<span>*</span></label>
                            <select className="form-select mt-2 w-100 px-1" aria-label="Default select example" onChange={(e)=>{
                                setData({...data,currentBusiness:e.target.value});
                            }}>
                                <option value="لا يوجد">لا يوجد</option>
                                <option value="حكومى">حكومى</option>
                                <option value="شبه حكومى">شبه حكومى</option>
                                <option value="خاص">خاص</option>
                            </select>
                        </div>
                    </div>
                    <button type="submit"  className="btn mt-3 w-lg-25 w-50 btn-primary">طلب تسجيل</button>
                </form>
            </div>
            <div className={`massage ${!done&&"active"}`}>
                <i className="bi bi-x-square-fill" onClick={()=>{setDone(false)}}></i>
                تم إرسال طلب الانضمام لمجموعات الواتساب بنجاح ، سيتم دراسة الطلب من قبل الفريق والرجوع إليكم قريباً ، شكراً لكم!
            </div>
        </section>
        
    )
}

