// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const contactProjectFirebaseConfig = {
    apiKey: "AIzaSyAvTY6PgO5G4J5g9ij6E2PzkS0CSlmMuXU",
    authDomain: "contact-9388e.firebaseapp.com",
    projectId: "contact-9388e",
    storageBucket: "contact-9388e.appspot.com",
    messagingSenderId: "982272409598",
    appId: "1:982272409598:web:5c75e08457eed074dc8aaf",
    measurementId: "G-YKB34YWBNN"
};

// Initialize Firebase
const appcontact = initializeApp(contactProjectFirebaseConfig, "CONCATS");

export const dbcontact = getFirestore(appcontact);