import React from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { opinons } from '../Assets/opinions'; 
export default function Says() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };
    return (
        <section className='Says container mt-5'>
            <h2 className="main-title mb-4 ">قالوا عنا</h2>
            <div className='row w-100'>
                <Slider {...settings}>
                    {opinons.map((item,index)=>{
                        return(<div key={index} className="w-100 custom-card px-2">
                            <div className='stars w-100 flex-column flex-lg-row'>
                                <div className='starr'>
                                    <i className="bi bi-star-fill me-1"></i>
                                    <i className="bi bi-star-fill me-1"></i>
                                    <i className="bi bi-star-fill me-1"></i>
                                    <i className="bi bi-star-fill me-1"></i>
                                    <i className="bi bi-star-fill"></i>
                                </div>
                                <p className='my-2 mx-0 fs-6 custom-p'>{item.name}<i className="bi bi-person-fill ms-1"></i></p>
                            </div>
                            <p className='my-3 fs-6 custom-pos'>{item.pos}<i className="bi bi-building ms-1"></i></p>
                            <p className='px-0 px-lg-2  text-center text-lg-right'>{item.opn}</p>
                        </div>)
                    })}
                </Slider>
            </div>
        </section>
    )
}
