import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
export default function Dashboard() {
    let navigator = useNavigate();
    if(sessionStorage.getItem('user')===null){
        navigator('../signup');
    }
    return (
        <div className='container dashboard'>
            <h1 className='mt-2'>Dashboard</h1>
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <Link to={"cvss"} className="nav-link" aria-current="page" href="#">السير الذاتيه</Link>
                </li>
                <li className="nav-item">
                    <Link to={"services"} className="nav-link" aria-current="page" href="#">طلبات الخدمات</Link>
                </li>
                <li className="nav-item">
                    <Link to={"UsersMemberships"} className="nav-link" aria-current="page" href="#">طلبات العضويه</Link>
                </li>
                <li className="nav-item">
                    <Link to={"eventsmembes"} className="nav-link" aria-current="page" href="#">طلبات الانضمام الفعاليات</Link>
                </li>
                <li className="nav-item">
                    <Link to={"watsapp"} className="nav-link" aria-current="page" href="#">طلبات الواتساب</Link>
                </li>
                <li className="nav-item">
                    <Link to={"eventes"} className="nav-link" aria-current="page" href="#">الفعاليات</Link>
                </li>
                <li className="nav-item">
                    <Link to={"nwes"} className="nav-link" aria-current="page" href="#">الاخبار</Link>
                </li>
                <li className="nav-item">
                    <Link to={"tweets"} className="nav-link" aria-current="page" href="#">التغريدات</Link>
                </li>
                <li className="nav-item">
                    <Link to={"contact"} className="nav-link" aria-current="page" href="#">التواصل</Link>
                </li>
            </ul>
            <Outlet/>
        </div>
    )
}
