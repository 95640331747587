import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import './css/style.css';
import Footer from './components/Home/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import AdministrativeTeam from './components/AdministrativeTeam';
import MembershipsSingls from './components/MembershipsSingls';
import Membershipscompanys from './components/Membershipscompanys';
import Shrot from './components/Memberships/Shrot';
import Aboutus from './components/Aboutus';
import Services from './components/Services';
import Watsapp from './components/Watsapp';
import Contact from './components/Contact';
import Contactdashbord from './components/Dashboard/Contact';
import Privacy from './components/Privacy';
import CV from './components/CV';
import AdvisoryBoard from './components/AdvisoryBoard';
import Says from './components/Says';
import Dashboard from './components/Dashboard';
import EventsHandle from './components/Dashboard/EventsHandle';
import Nweshandler from './components/Dashboard/Nweshandler';
import Tweetshandler from './components/Dashboard/Tweetshandler';
import MemberForm from './components/Forms/MemberForm';
import UsersMemberships from './components/Dashboard/UsersMemberships';
import logo2 from './Assets/ihrsp@2x.png';
import Cvshowadmin from './components/Dashboard/Cvshowadmin';
import EventFormhandeler from './components/Home/EventFormhandeler';
import Eventsmembes from './components/Dashboard/Eventsmembes';
import Reports from './components/Reports';
import ServicesForm from './components/Forms/ServicesForm';
import Userservices from './components/Dashboard/Userservices';
import Watsappusers from './components/Dashboard/Watsappusers';
import WatsappForm from './components/Forms/WatsappForm';
import Signup from './components/Dashboard/Signup';
import Default from './components/Default';
function App() {
  const [intro,setIntro] = useState(true);
  const [display,setDisplay] = useState(true);
  useEffect(()=>{
    let handler = setTimeout(()=>{
      setIntro(false);
      clearTimeout(handler);
    },1200);
    let handler2 = setTimeout(()=>{
      setDisplay(false);
      clearTimeout(handler2);
    },1700)
  },[])
  return (
    <div className='App'>
      <Navbar />
      <Routes>
        <Route path='' exact element={<Home />}/>
        <Route path='AdministrativeTeam'  element={<AdministrativeTeam />}/>
        <Route path='MembershipsSingls'  element={
          <React.Fragment>
            <MembershipsSingls />
            <Shrot />
          </React.Fragment>
        }/>
        <Route path='signup' element={<Signup />}/>
        <Route path='MembershipsSingls/Memberships/:id' element={
          <MemberForm />
        }/>
        <Route path='Services/Services/:id' element={
          <ServicesForm />
        }/>
        <Route path='Watsapp/Watsapp/:id' element={
          <WatsappForm />
        }/>
        
        <Route path='Membershipscompanys'  element={
          <React.Fragment>
            <Membershipscompanys />
            <Shrot />
          </React.Fragment>
        }/>
        <Route path='Membershipscompanys/Memberships/:id' element={
          <MemberForm />
        }/>
        <Route path='Aboutus'  element={
          <>
            <Aboutus />
          </>
        }/>
        <Route path='/events/:param'  element={<EventFormhandeler />}/>
        <Route path='dashboard'  element={<Dashboard />}>
              <Route path='eventes'  element={<EventsHandle />} />
              <Route path='services'  element={<Userservices />} />
              <Route path='watsapp'  element={<Watsappusers />} />
              <Route path='nwes'  element={<Nweshandler />} />
              <Route path='tweets'  element={<Tweetshandler />} />
              <Route path='UsersMemberships'  element={<UsersMemberships />} />
              <Route path='cvss'  element={<Cvshowadmin />} />
              <Route path='contact'  element={<Contactdashbord />} />
              <Route path='eventsmembes'  element={<Eventsmembes />} />
        </Route>
        
        <Route path='AdvisoryBoard'  element={
          <>
            <AdvisoryBoard />
          </>
        }/>
        <Route path='Says'  element={
          <>
            <Says />
          </>
        }/>
        <Route path='Reports'  element={
            <Reports />
        }/>
        <Route path='Privacy'  element={
          <>
            <Privacy />
          </>
        }/>
        <Route path='CV'  element={<CV/>}/>
        <Route path='Services'  element={
          <>
            <Services />
          </>
        }/>
        <Route path='Watsapp'  element={
          <>
            <Watsapp />
          </>
        }/>
        <Route path='Contact'  element={
          <>
            <Contact />
          </>
        }/>
        <Route path='*'  element={
          <>
            <Default />
          </>
        }/>
      </Routes>
      <Footer />
      {
        (display)&&<div className={`animatedLog ${(!intro)&&"active"}`}>
          <img className='res-anim' style={{height:'200px',display:'block'}} src={logo2}alt="logo"/>
        </div>
      }
    </div>
  );
}

export default App;
