import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { dbevents } from '../../firebase/firebaseevents';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import animvedio from '../../Assets/vedio/animation-success.mp4'
export default function EventFormhandeler() {
    let {param} = useParams();
    const [data,setData] = useState({
        title:param,
    });
    const [done,setDone] = useState(false);
    const navigator = useNavigate();
    const handleSubmit = async()=>{
        try{
                await addDoc(collection(dbevents,"eventsform"),{...data,timeStamp:serverTimestamp()});
                setDone(true);
                let handler = setTimeout(()=>{
                    navigator(0);
                    clearTimeout(handler);
                },1700);
        }catch(err){
            console.log(err);
        }
    };
    return (
        <section  className='customeventForm container mt-3'>
            {(param==="ملتقى الرابع")&&<div className='text-right'> 
                <p className='fs-2 text-primary'>ملحوظات هامه</p>
                <p><i className="bi bi-1-square-fill fs-6 ms-1"></i>الملتقى الرمضاني الرابع  1444</p>
                <p><i className="bi bi-2-square-fill fs-6 ms-1"></i>دعوة سحور! أعضاء منصة iHR</p>
                <p> <i className="bi bi-3-square-fill fs-6 ms-1"></i>يوم السبت 8 أبريل 2023</p>
                <p>ملتقى رمضاني سنوي يجمع فيه ممارسين ومتخصصين في الموارد البشرية ، ودردشات ونقاشات مفتوحة حول ممارسات الموارد البشرية والتطوير التنظيمي.</p>
                <p> <i className="bi bi-4-square-fill fs-6 ms-1"></i>التاريخ : 17 رمضان الموافق 8 ابريل </p>
                <p> <i className="bi bi-5-square-fill fs-6 ms-1"></i>اليوم : يوم السبت </p>
                <p> <i className="bi bi-6-square-fill fs-6 ms-1"></i>الساعة : 10:00 م</p>
                <p className='text-danger'> <i className="bi bi-7-square-fill fs-6 ms-1"></i>الرسوم : 145 ر.س</p>
                <p className='mb-1 mt-2'>المكان : فندق بريرا النخيل / مدينة الرياض <a href='http://shorturl.at/juG14' className='me-2'>http://shorturl.at/juG14</a></p>
                <p className='mb-1 mt-2'>تقرير الملتقى الثالث 2022 على تويتر : <a href='https://shorturl.at/esEMW' className='me-2'>https://shorturl.at/esEMW</a></p>
                <p className='mb-1 mt-2'>تقرير الملتقى الثالث 2022 على لنكيدإن : <a href='https://shorturl.at/cerF2' className='me-2'>https://shorturl.at/cerF2</a></p>
                <hr />
            </div>}
            <form onSubmit={(e)=>{
                e.preventDefault();
                handleSubmit();
            }}>
                <p className='fs-4 text-secondary'>طلب التسجيل</p>
                <div className='d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap mb-3'>
                    <div className='w-100 w-lg-50 ms-lg-3 d-flex justify-content-center align-items-start flex-column' value={data.name!==undefined?data.name:""}>
                        <label>الاسم<span>*</span></label>
                        <input placeholder='الاسم' className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                            setData({...data,name:e.target.value});
                        }}/>
                    </div>
                    <div className='w-100 w-lg-50 d-flex justify-content-center align-items-start flex-column px-1' value={data.email!==undefined?data.email:""}>
                        <label>البريد الالكترونى<span>*</span></label>
                        <input className="rounded p-1 mt-2 w-100" placeholder='البريد الالكترونى'  required type={"email"}  onChange={(e)=>{
                            setData({...data,email:e.target.value});
                        }}/>
                    </div>
                </div>
                                <div className='d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap mb-3'>
                    <div className='w-100 w-lg-50 ms-lg-3 d-flex justify-content-center align-items-start flex-column' value={data.name!==undefined?data.name:""}>
                        <label>اسم المنظمة<span>*</span></label>
                        <input placeholder='اسم المنظمة' className="rounded p-1 mt-2 w-100 px-1" required type={"text"} onChange={(e)=>{
                            setData({...data,orgnization:e.target.value});
                        }}/>
                    </div>
                    <div className='w-100 w-lg-50 d-flex justify-content-center align-items-start flex-column px-1' value={data.email!==undefined?data.email:""}>
                        <label>المسمى الوظيفي<span>*</span></label>
                        <input className="rounded p-1 mt-2 w-100" placeholder='المسمى الوظيفي'  required type={"text"}  onChange={(e)=>{
                            setData({...data,position:e.target.value});
                        }}/>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap mb-3'>
                    <div className='w-100 w-lg-50 ms-lg-3 d-flex justify-content-center align-items-start flex-column' value={data.name!==undefined?data.name:""}>
                        <label>رقم الهاتف<span>*</span></label>
                        <input placeholder='رقم الهاتف' className="rounded p-1 mt-2 w-100 px-1" required type={"tel"} onChange={(e)=>{
                            setData({...data,phone:e.target.value});
                        }}/>
                    </div>
                    <div className='w-100 w-lg-50 d-flex justify-content-center align-items-start flex-column px-1' value={data.email!==undefined?data.email:""}>
                        <label>رابط لينكدان<span>*</span></label>
                        <input className="rounded p-1 mt-2 w-100" placeholder='رابط لينكدان'  required type={"url"}  onChange={(e)=>{
                            setData({...data,linkedin:e.target.value});
                        }}/>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">تسجيل</button>
            </form>
            {(done)&&<div className={"massage-custom"}>
                <i className="bi bi-x-square-fill" onClick={()=>{
                    setDone(false)
                }}></i>
                <p>اهلا ومرحبا بك</p>
                <video id="video"  autoPlay controls="" loop muted type="video/mp4" preload="none" >
                    <source src={animvedio}></source>
                </video>
            </div>}
        </section>
    )
}

