export const futsures = [
    {
        id:1,
        text:`عقد ورش العمل والدورات التدريبية المتخصصة في الموارد البشرية
        `,
    },
    {
        id:2,
        text:`لقاء واستضافة أخصائيي موارد بشرية ناجحين محليين ودوليين
        `,
    },
    {
        id:3,
        text:`إنشاء المدونات، المنتديات والاجتماعات الافتراضية تقديم المهارات والمعارف في مجال الموارد البشرية للعاملين المهتمين في هذا المجال من غير المتخصصين
        `,
    },
    {
        id:4,
        text:`إجراء ورش عمل تعليمية لطلاب الدراسات العليا والطلاب المتوقع تخرجهم، عقد ورش عمل تعليمية وإرشادية حول ثقافة العمل، من خلال تثقيفهم حول قانون العمل السعودي ونظام التأمينات الاجتماعية
        `,
    },
    {
        id:5,
        text:`الدخول في شراكات مع الجهات التعليمية والمهنية لتحقيق أهداف المجموعة
        `,
    },
    {
        id:6,
        text:`المشاركة في تطوير الممارسات والمهارات في إدارات الموارد البشرية.
        `,
    }
]